import Geoloc from '~/utils/geoloc'
import { useTheme } from '~/contexts/ThemeContext';

const SetLoc = () => {
  const { mapObj, getDevice } = Geoloc()
  const { locate, setLocate } = useTheme()

  const checkZip = async (zip) => {
    const new_loc = await mapObj(zip)
    const testZip = new_loc?.zip && new_loc?.coords

    if (testZip) {
      const loc = {
        ...locate,
        usrZip: new_loc.zip,
        usrCountry: new_loc?.country,
        usrCoords: new_loc?.coords
      }
      setLocate(loc)
      return true
    }

    if (locate.usrZip !== null) {
      const loc = {
        ...locate,
        usrZip: null,
        usrCountry: null,
        usrCoords: null
      }
      setLocate(loc)
    }
    return false
  }

  const deviceLoc = async () => {
    const new_loc = await getDevice()
    const testZip = new_loc?.zip && new_loc?.coords

    if (testZip) {
      const loc = {
        ...locate,
        usrZip: new_loc.zip ,
        usrCountry: new_loc.country,
        usrCoords: new_loc.coords
      }
      setLocate(loc)
      return true
    }

    if (locate.usrZip !== null) {
      const loc = {
        ...locate,
        usrZip: null,
        usrCountry: null,
        usrCoords: null
      }
      setLocate(loc)
    }
    return false
  }


  

  return { checkZip, deviceLoc }
}

export default SetLoc