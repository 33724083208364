import * as React from "react";

const IconLoc = () => {
  return (
    <svg icn-loc={''} aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path fill="currentColor" d="M256 0c13.3 0 24 10.7 24 24V65.5C366.8 76.3 435.7 145.2 446.5 232H488c13.3 0 24 10.7 24 24s-10.7 24-24 
      24H446.5C435.7 366.8 366.8 435.7 280 446.5V488c0 13.3-10.7 24-24 24s-24-10.7-24-24V446.5C145.2 435.7 76.3 366.8 65.5 280H24c-13.3 
      0-24-10.7-24-24s10.7-24 24-24H65.5C76.3 145.2 145.2 76.3 232 65.5V24c0-13.3 10.7-24 24-24zM112 256a144 144 0 1 0 288 0 144 144 0 1 
      0 -288 0zm192 0a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm-144 0a96 96 0 1 1 192 0 96 96 0 1 1 -192 0z"></path>
    </svg>
      
  )
}

export default IconLoc

