// extracted by mini-css-extract-plugin
export var afterpay = "quick-module--afterpay--6e804";
export var arrowbtn = "quick-module--arrowbtn--b2864";
export var body = "quick-module--body--3493d";
export var close = "quick-module--close--68c88";
export var color = "quick-module--color--52d7a";
export var finalsale = "quick-module--finalsale--7ba76";
export var foot = "quick-module--foot--73c40";
export var forw = "quick-module--forw--bf81f";
export var gallery = "quick-module--gallery--318b5";
export var hide = "quick-module--hide--e339e";
export var icon_close = "quick-module--icon_close--71012";
export var image = "quick-module--image--4352b";
export var images = "quick-module--images--a06e6";
export var imageswr = "quick-module--imageswr--fc680";
export var img = "quick-module--img--8d22a";
export var low = "quick-module--low--58782";
export var notices = "quick-module--notices--1f7f7";
export var off = "quick-module--off--1fbeb";
export var option = "quick-module--option--e6bf7";
export var pdpCtaAvailable = "quick-module--pdp-cta-available--a6608";
export var pdpSecMain = "quick-module--pdp-sec-main--41cb6";
export var pdpSelectorOption = "quick-module--pdp-selector-option--1231f";
export var pdpSelectorOptions = "quick-module--pdp-selector-options--4b5c9";
export var pdp_label = "quick-module--pdp_label--59dad";
export var pdp_selector = "quick-module--pdp_selector--483e2";
export var pdp_selectorSizes = "quick-module--pdp_selector-sizes--66abb";
export var pdptColor_wr = "quick-module--pdpt-color_wr--0d1d3";
export var price = "quick-module--price--993ab";
export var price_sale = "quick-module--price_sale--64b3a";
export var prodside = "quick-module--prodside--6be78";
export var prodside_details = "quick-module--prodside_details--85b82";
export var prodside_temp = "quick-module--prodside_temp--358f3";
export var productDetail = "quick-module--product-detail--cdf6d";
export var q_crx = "quick-module--q_crx--ac836";
export var q_cta = "quick-module--q_cta--7f295";
export var qrec = "quick-module--qrec--372df";
export var qrecs_head = "quick-module--qrecs_head--7dadd";
export var qrecs_prods = "quick-module--qrecs_prods--386e3";
export var qrecs_wr = "quick-module--qrecs_wr--89bf9";
export var qsizes = "quick-module--qsizes--cc27c";
export var quick = "quick-module--quick--4289c";
export var returns = "quick-module--returns--84c4e";
export var ship_info = "quick-module--ship_info--e7036";
export var shortd = "quick-module--shortd--38fd7";
export var title = "quick-module--title--d17eb";
export var title_price = "quick-module--title_price--17586";
export var top = "quick-module--top--97cb3";
export var wasredir = "quick-module--wasredir--38090";