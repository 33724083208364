
const Kora = () => {

  const kGetCookie = (name) => {
    if (typeof document === 'undefined') return null
    const nameEQ = `${name}=`
    var ca = document.cookie.split(';')
    for(var i=0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1,c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length)
    }
    return null
  }

  const kSetCookie = (name, value) => {
    if (typeof document === 'undefined') return
    const is_local = window.location.hostname.includes('localhost')
    let expires = ''
    let date = new Date()
    date.setTime(date.getTime() + (400 * 24 * 60 * 60 * 1000))
    expires = `; expires=${date.toUTCString()}`
    document.cookie = `${name}=${(value || '')}${expires}; path=/;${is_local ? '' : ' domain=buckmason.com'}`
  }

  // need to move in setting referrer

  const kGetDevice = () => {
    const touches = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
    // const mobileagent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    // const mobileagent = navigator.userAgent
    // ma: mobileagent
    const mobile_size = window.innerWidth < 820
    const tablet_size = window.innerWidth > 819
    return {
      tch: touches ? 1 : 0, 
      mo: mobile_size ? 1 : 0, 
      tab: (touches && tablet_size) ? 1 : 0
    }
  }

  const kNewestStamp = () => {
    const locl_data = localStorage.getItem('kra') && parseInt(localStorage.getItem('kra'))
    const cook = kGetCookie('kra')
    const cook_data = cook && parseInt(cook)

    if (!locl_data && !cook_data) return false
    if (locl_data && !cook_data) return locl_data
    if (!locl_data && cook_data) return cook_data
    if (locl_data === cook_data) return locl_data

    return locl_data > cook_data ? locl_data : cook_data
  }

  const kHandleIp = async () => {
    let loc = localStorage.getItem('kra_i')
    if (loc) {
      loc = loc.split('|')
      return {ip: loc[0], hash: loc[1]}
    }
    let response = await fetch('https://api.ipify.org?format=json')
    response = await response.json()
    const ip = response?.ip || ''

    const hashCode = str => [...str].reduce((h, c) => Math.imul(31, h) + c.charCodeAt(0) | 0, 0)
    const hash = Math.abs(hashCode(ip)).toString(16)
    localStorage.setItem('kra_i',`${ip}|${hash}`)
    return {ip, hash}
  }

  const kPing = (hash) => {
    const obj = {ih: hash,p_ng:1}
    kSend(obj)
  }

  const kSend = async (obj) => {
    const is_local = window.location.hostname.includes('localhost')
    let url = `https://in.kora.io/weqrfv/${is_local ? '94e04e6a' : '6100ce37'}`
    // url = `http://in.localhost:3002/weqrfv/9a9ecdd6`

    try {
      await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-Foo-Bar': 'jgp0Ru3nvlCV6vy21z'
        },
        body: JSON.stringify(obj)
      })
      .then(res => {
        if (res) return
      })
    } catch (error) {}
  }

  const doKora = async () => {
    if (typeof localStorage === "undefined") return
    // const is_local = window.location.hostname.includes('localhost')
  
    const userag = navigator.userAgent
    const bots = ['bot','facebookexternalhit','facebookcatalog']
    if (bots.find(x => userag.toLowerCase().includes(x)) ) return
    
    const cust = !!localStorage.getItem('cu')
    const today_start = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })).setHours(0, 0, 0, 0)
    const newest_stamp = kNewestStamp()
    
    let known_today = newest_stamp && newest_stamp >= today_start
    if (known_today && (Date.now() - newest_stamp > 3600000)) {
      known_today = false
      localStorage.removeItem('kra_i')
    }
    const unknown = !newest_stamp
    const { ip, hash } = await kHandleIp()
    
    if (known_today) {
      kPing(hash)
      return
    }

    let device = kGetDevice()
    if (!device.tch && !device.mo && !device.tab) device = {d: 1}
    
    const now = Date.now()
    kSetCookie('kra', now)
    localStorage.setItem('kra', now)

    const user_types = {
      new: 0,
      returning: 1
    }

    const refer = JSON.parse(kGetCookie('re'))

    const obj = {
      ip: ip,
      ih: hash,
      ut: user_types[unknown ? 'new' : 'returning'],
      dv: device
    }
    if (cust) {
      obj.cu = cust ? 1 : 0
      obj.ut = 1
    }
    // referer keys
    // p: window.location.pathname,
    // r: document.referrer.includes('buckmason.com') ? '' : document.referrer,
    // a: params.get('utm_campaign') || false,
    // o: params.get('utm_content') || false,
    // m: params.get('utm_medium') || false,
    // s: params.get('utm_source') || false,
    // t: params.get('utm_term') || false,
    // d: Date.now

    // comp ids
    // 6100ce37 bm
    // 94e04e6a te

    if (refer && (refer?.r !== '' || !!refer?.a || !!refer?.o || !!refer?.m || !!refer?.s)) {
      delete refer.d
      obj.ref = refer
    } else {
      obj.p = refer.p
    }
    kSend(obj)
  }








  return { doKora }
};

export default Kora;






