import React, { useState, useEffect, useRef } from "react"
import { useTheme } from '~/contexts/ThemeContext'
import SetLoc from '~/utils/set-loc'
import { jnstyls } from '~/utils/helpers'
import useAnalytics from '~/utils/analytics'

import PanelRegion from './stores-region'
import IconClose from '~/components/icons/close'
import IconLoc from '~/components/icons/location'

import { aria_btn, solid_btn, bg } from "~/styles/app.module.scss"
import * as styles from "~/styles/stores/store_panel.module.scss"

const StoresPanel = ({ variant }) => {
  const { 
    isMobile, setStoresOpen, setFreeze, locate, setLocate, collPanel, collGender,
    storesData
  } = useTheme()

  const { checkZip, deviceLoc } = SetLoc()
  const { ga_store_toggle } = useAnalytics()

  const bgRef = useRef(null)
  const panelRef = useRef(null)
  const inputRef = useRef(null)

  let gender = false
  if (collGender && collGender !== 'u') gender = collGender

  const [didLoad, setDidLoad] = useState(false)
  const [regions, setRegions] = useState([])
  const [inputKey, setInputKey] = useState('zip-input-1')

  const handleClose = (both=false) => {
    if (!bgRef.current || !panelRef.current) return

    if (document.activeElement && panelRef.current.contains(document.activeElement)) {
      document.activeElement.blur()
    }
    bgRef.current.setAttribute('aria-hidden', 'true')
    panelRef.current.setAttribute('aria-hidden', 'true')
    setTimeout(() => {
      if (!(both && collPanel)) setFreeze(false)
      setStoresOpen(false)
    }, 100)
  }

  const selectStore = (store, isUser=false) => {
    let short_names = []
    if ( locate.currStores.includes(store.short_name) ) {
      ga_store_toggle('disable', store.store_title)
    } else {
      short_names = [store.short_name]
      ga_store_toggle('enable', store.store_title)
    }
    let store_gend = false
    if (!(store.avail_mens && store.avail_womens)) {
      store_gend = store.avail_mens ? 'm' : 'w'
    }
    setLocate({
      ...locate,
      currStores: short_names,
      storeGend: store_gend
    })
    handleClose()
  }

  const handleZip = (e) => {
    if (regions.length < 1) return
    const types = ['insertText','insertFromPaste']
    if (!types.includes(e.nativeEvent.inputType)) return

    const val = inputRef.current.value
    if (val && val.length >= 5) {
      checkZip(val)
    }
  }

  const handleZipClick = () => {
    const val = inputRef.current.value
    if (val && val.length >= 5) {
      checkZip(val)
    }
  }

  const handleDevice = async () => {
    inputRef.current.value = 'Checking location...'
    const device = await deviceLoc()
    return device
  }

  const check_dist = (region, index) => {
    if (!region.hasOwnProperty('distance')) return true
    const not_empty = region.distance !== false 
    if (not_empty && (index === 0 && region.distance > 100)) return true
    return not_empty && region.distance < 100
  }

  const sortRegions = () => {
    let sorted = structuredClone(storesData).filter(x => !!x)

    if (gender && gender !== 'u') {
      const handle_stub = gender === 'm' ? '-women' : '-men'
      sorted.forEach(reg => reg.stores = reg.stores.filter(x => !x.page?.includes(handle_stub)))
    }
    sorted.forEach(reg => reg.stores = reg.stores.filter(x => !!x.pickup_enabled))
    sorted = sorted.filter(x => x?.stores?.length > 0)

    setRegions(sorted)
  }

  useEffect(() => {

    const storesListen = (e) => {
      if (e.key === 'Escape') {
        window.removeEventListener('keydown', storesListen)
        handleClose()
      }
    }
    if (!isMobile) window.addEventListener('keydown', storesListen)

    return () => {
      window.removeEventListener('keydown', storesListen)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (didLoad || storesData.length < 1) return
    setFreeze(true)

    const bg_el = bgRef.current
    const panel_el = panelRef.current

    setDidLoad(true)

    setTimeout(() => {
      bg_el.setAttribute('aria-hidden', 'false')
      panel_el.setAttribute('aria-hidden', 'false')
    }, 100)
  }, [storesData, setFreeze, didLoad])

  useEffect(() => {
    if (!(locate && storesData)) return
    sortRegions()
    setInputKey('zip-input-' + Date.now())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locate, storesData])

  return (
    <>
      <button 
        type="button"
        className={bg} 
        aria-hidden="true" 
        ref={bgRef} 
        onClick={(e)=> {handleClose()}}
        aria-label={'Close stores panel'}
      />
      <aside 
        className={styles.store_panel}
        ref={panelRef}
        id="ar_sidebar-p-stores" 
        aria-label="Choose a store to filter by" 
        role="dialog" 
        aria-modal="true" 
        aria-hidden="true"
      >
        <div className={[styles.head].join(' ')}>
          <button 
            type="button"
            className={[aria_btn, styles.close].join(' ')}
            aria-label="Close stores sidebar"
            onClick={(e)=> {handleClose()}} 
          >
            <IconClose icon_classes={''} />
          </button>

          <div className={styles.search}>
            <input 
              key={inputKey}
              id="stores-search_input"
              inputMode="numeric" 
              type="text" 
              aria-label="Enter Zip Code to Find Closest Store"
              defaultValue={locate ? locate?.usrZip || locate.zip : undefined}
              placeholder="Enter zip code to find your store"
              onInput={(e) => handleZip(e)}
              ref={inputRef}
            />
            <button
              type="button" 
              className={styles.loc_btn} 
              aria-label="Find your location"
              onClick={(e) => handleDevice()}
            >
              <IconLoc />
            </button>
            <button 
              type="button"
              className={styles.search_btn} 
              aria-label="Submit search"
              onClick={(e) => handleZipClick()}
            />
          </div>
        </div>

        <div className={jnstyls([styles.content, styles.pdp_content])}>

          <div className={styles.regions}>
            {regions && regions.map( (region, index) => 
              <React.Fragment key={`${region.region}`}>
                <PanelRegion 
                  regionData={region} 
                  selectStore={selectStore} 
                  showOpen={check_dist(region, index)}
                />
              </React.Fragment>
            )}
          </div>
        </div>

        <div className={styles.back}>
          <button 
            type="button"
            className={[aria_btn, solid_btn].join(' ')}
            onClick={(e) => handleClose(true)}
          >
            Continue Shopping
          </button>
        </div>
      </aside>
    </>
  )
}

export default StoresPanel






