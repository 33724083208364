import React, { useState, useEffect, useRef, useCallback } from "react";
import Flink from '~/components/shared/flink'
import { useProduct } from '~/contexts/ProductContext'
import { useTheme } from '~/contexts/ThemeContext'
import { imgSize, jnstyls } from '~/utils/helpers'

import IconClose from '~/components/icons/close'
import { IconChevLeft, IconChevRight } from '~/components/icons/chevrons'
import Colorways from '~/components/pdp/colorways'
import Alts from '~/components/pdp/alts'
import Sizes from '~/components/shared/sizes'
import Cta from '~/components/pdp/cta'
import Bis from '~/components/pdp/bis'
import ShipPickup from '~/components/pdp/pickup'
import FavProd from '~/components/shared/fav-prod'
import QuickRecs from '~/components/panels/quick-recs'

import * as tstyles from "~/styles/pdp/title.module.scss"
import * as styles from "~/styles/panels/quick.module.scss"


const Quick = ({ handleClose, colorClick }) => {
  const { isSHM, customer } = useTheme()
  const { product, showCta, showBis, showAfterp, recProds, shipCheck, prodInv } = useProduct()

  const { 
    handle, title, pl_name, variants, vpricMin, vpricMax, percent_off, first_product_price, 
    first_full_price, short_description, is_gift, is_single, outfits, color, alt_cuts, sizing, 
    shop_id
  } = product

  const oos_replace = product.hasOwnProperty('oos_replace') && product.oos_replace
  const media = product.media.filter(x => x.type === 'IMAGE')

  const [quickRecs, setQuickRecs] = useState({})

  const show_newship = () => {
    if (!prodInv) return false
    const wh_combo = prodInv && (prodInv?.inventory?.DW?.length > 0 || !!prodInv?.inventory['BL - Ecommerce']?.length > 0)
    const ship_avail = !isSHM ? shipCheck?.ship : wh_combo
    return (!is_gift && showCta && (ship_avail || shipCheck?.pickup))
  }

  const total = (media && media.length) || 0
  const [pos, setPos] = useState(0)
  const [backon, setBackon] = useState(false)
  const [forwon, setForwon] = useState(true)

  const wrapRef = useRef(null)
  const slideRef = useRef(null)

  const handleBack = () => {
    const inview = arrow_inview()
    let new_pos = inview[0] - 1 - inview.length
    if (new_pos < 0) new_pos = 0

    setPos(new_pos)
  }
  const handleForward = () => {
    const inview = arrow_inview()
    let new_pos 
    if (inview.length) {
      new_pos = inview[inview.length - 1] + 1
      if (new_pos + inview.length >= total) new_pos = total
      if (pos === total) new_pos = 0
    } else {
      new_pos = total
    }
    if (!slideRef.current) {
      const arr = Array.from(document.querySelectorAll(`.${styles.image}`))
      slideRef.current = arr[new_pos - 1]
    }

    setPos(new_pos)
  }

  const arrow_inview = () => {
    const wrap = wrapRef.current
    const wrap_rect = wrap.getBoundingClientRect()
    const arr = Array.from(document.querySelectorAll(`.${styles.image}`))

    let inview = []
    arr.forEach((x, index) => {
      const el = x.getBoundingClientRect()
      const el_left = el.left - wrap_rect.left 
      const el_right = el.left + el.width - wrap_rect.left - 20
      if (el_left >= 0 && el_right < wrap_rect.width) inview.push(index)
    })
    return inview
  }

  const deskChange = useCallback(() => {
    const arr = Array.from(document.querySelectorAll(`.${styles.image}`))
    if (arr.length < 1) return
    const wrap = wrapRef.current
    const wrap_rect = wrap.getBoundingClientRect()
    const curr_scroll = wrap.scrollLeft

    const next_ind = pos === arr.length ? arr.length - 1 : pos
    const next = arr[next_ind].getBoundingClientRect()
    const next_scroll = next.left + (curr_scroll - wrap_rect.left) - 10

    wrap.scrollTo({
      top: 0,
      left: next_scroll,
      behavior: 'smooth'
    });
  }, [pos])

  const checkScroll = () => {
    const wrap = wrapRef.current
    const max_scrll = Math.round(wrap.scrollWidth - wrap.getBoundingClientRect().width) - 1
    
    const new_back = wrap.scrollLeft >= 5
    if (new_back !== backon) setBackon(new_back)

    const new_forw = wrap.scrollLeft <= max_scrll
    if (new_forw !== forwon) setForwon(new_forw)
  }

  useEffect(() => {
    deskChange()
    
  }, [pos, slideRef, deskChange])

  useEffect(() => {
    const el = wrapRef.current

    if ( el ) {
      el.addEventListener('scroll', checkScroll, { passive: true })
      return () => {
        el.removeEventListener('scroll', checkScroll)
      }
    }
  })

  useEffect(() => { 
    // setDidLoad(true)
    setQuickRecs({recs: recProds || [], outfits: outfits})

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recProds])

  if (!product) {
    return (
      <></>
    )
  }

  return (
    <div className={styles.body}>
      <button 
        type="button"
        className={styles.close}
        aria-label="Close sidebar" 
        onClick={(e)=> {handleClose()}}
      >
        <IconClose icon_classes={styles.icon_close} />
      </button>

      <div className={styles.top}>
        <Flink href={`/products/${handle}`} className={styles.title} onClick={(e)=> {handleClose()}}>
          {is_single ? title : pl_name}
        </Flink>

        <div className={jnstyls([tstyles.price,styles.price])}>
          {is_gift && (
            <>
              ${vpricMin}&ndash;${vpricMax}
            </>
          )}
          {(!is_gift && percent_off) ? (
            <>
              <span className={tstyles.price_perc}>
                ${first_product_price}
              </span>
              <span className={tstyles.price_full}>
                ${first_full_price}
              </span>
              <span className={jnstyls([tstyles.price_sale, styles.price_sale])}>
                ({percent_off}% OFF)
              </span>
            </>
          ) : (
            <>
              ${first_product_price}
            </>
          )}
        </div>
        {short_description && (
          <div className={styles.shortd}>
            {short_description}
          </div>
        )}
      </div>

      {(media && media.length > 0 ) && (
        <div className={styles.gallery}>
          <div className={styles.imageswr} ref={wrapRef}>
            <div className={styles.images}>
              {media.map( (image, index) => 
                <Flink 
                  key={`group_${index}`} 
                  href={`/products/${handle}`}
                  className={styles.image} 
                  style={{textDecoration: 'none'}}
                  ref={pos === index ? slideRef : undefined}
                  onClick={(e)=> {handleClose()}}
                >
                  <img 
                    className={styles.img} 
                    src={imgSize(image.src, 700)}
                    alt={image.alt} 
                    width="815" height="1080"
                  />
                </Flink>
              )}
            </div>
          </div>
          {media.length > 2 && (
            <>
              <button 
                type="button"
                className={jnstyls([styles.arrowbtn, backon ? '' : styles.off])}
                aria-label="Scroll Back" 
                tabIndex="0"
                onClick={(e)=>{handleBack()}}
              >
                <IconChevLeft icon_classes="" />
              </button>
              <button 
                type="button"
                className={jnstyls([styles.arrowbtn, styles.forw])}
                aria-label="Scroll Forward" 
                tabIndex="0"
                onClick={(e)=>{handleForward()}}
              >
                <IconChevRight icon_classes="" />
              </button>
            </>
          )}
        </div>
      )}

      <div className={styles.prodside}>
        <div className="product-detail plpdp">
          {oos_replace && (
            <div className={styles.wasredir}>
              <span>Sorry, that color is no longer available, but check out the available colors below.</span>
            </div>
          )}

          {color && (
            <Colorways colorClick={colorClick} />
          )}

          {alt_cuts && (
            <Alts />
          )}
          {is_single && sizing && (
            <div className={''}>
              <div >
                ONE SIZE:
              </div>
              <div>
                <button type="button" className={`aria_btn`} pl-sizing="">
                  Dimensions / Fit Guide
                </button>
              </div>
            </div>
          )}

          <div data-product-detail-form="">
            <input type="hidden" id="bis-product" name="bis-product" value={shop_id} />
            
            {(!is_single && variants && variants.length > 0) && (
              <Sizes />
            )}

            {is_gift && (
              <>
                <input type="hidden" gift-hide="name" name="properties[Recipient Name]" value="" />
                <input type="hidden" gift-hide="email" name="properties[Recipient Email]" value="" />
                <input type="hidden" gift-hide="date" name="properties[Delivery Date]" value="" />
                <input type="hidden" gift-hide="message" name="properties[Message]" value="" />
              </>
            )}
          </div>
        </div>
      </div>

      <div className={styles.q_cta}>
        {(prodInv && showCta) && (
          <Cta />
        )}
        {(prodInv && showBis) && (
          <Bis />
        )}
        {showAfterp && (
          <div id={styles.afterpay}>
            <afterpay-placement 
              data-locale="en_US"
              data-currency="USD" 
              data-amount={product.first_product_price}
              data-modal-theme="white"
              data-logo-type="lockup"
            >{process.env.NODE_ENV !== "production" && (<p className='afterpay-paragraph'>After pay goes here</p>)}</afterpay-placement>
          </div>
        )}
        {(customer && localStorage.getItem('ward')) && (
          <div style={{textAlign: 'right'}}>
            <FavProd prod_id={product.mid} />
          </div>
        )}
      </div>

      {/*{(showCta && isLow && isLow <= 25) && (
        <div className={[styles.low].join(' ')}>
          {isLow <= 5 ? `${isLow} left in stock` : 'Low Stock'}
        </div>
      )}*/}

      {show_newship() && (
        <ShipPickup />
      )}

      {Object.keys(quickRecs).includes('recs') && (
        <QuickRecs recProds={quickRecs} />
      )}
    </div>
  );
}

export default Quick



