import React, { useState, useEffect, useRef } from "react";
import Flink from '~/components/shared/flink'
import { useTheme } from '~/contexts/ThemeContext';
import { imgSize } from '~/utils/helpers'
import useAnalytics from '~/utils/analytics'

import * as styles from "~/styles/header.module.scss"

const HeaderSubmenu = ({ panel, obj, journal_menu=false }) => {
  const { analytics, setAnalytics } = useTheme()
  const { ga_nav_click } = useAnalytics()

  const panelRef = useRef(null)

  const gender = obj.name.replace('new ', '')
  const menu = obj.menu ? obj.menu : obj.data
  const specials = ['Highlights','Promo','Journal','Collaborations','Featured']
  const promo = menu.find(x => x.name === 'Promo')

  const [isOpen, setIsOpen] = useState(panel === gender)
  const [journPrev, setJournPrev] = useState(null)

  const journ_stories = (data) => {
    return data.filter(x => x.name === 'stories')
  }

  const journ_other = (data) => {
    return data.filter(x => x.name !== 'stories')
  }

  const handleClick = (name, url) => {
    setIsOpen(false)
    gaClick(name, url)
  }

  const has_collabsfeats = () => {
    const collabsfeats = menu.filter(x => x.name === 'Collaborations' || x.name === 'Featured') 
    if (collabsfeats.length < 1) return false
    return collabsfeats
  }

  let feat_post = false
  if (obj.name === 'journal') {
    obj.data.forEach(cat => {
      const is_feat = cat.menu.find(x => x.is_feat)
      if (is_feat) feat_post = is_feat
    })
  }

  const gaClick = (name, url) => {
    const obj = {
      hierarchy: `${gender} > ${name}`,
      text: name,
      url: url
    }
    if (name.includes('Gift Cards')) {
      setAnalytics({...analytics, list: 'header menu'})
    }
    ga_nav_click(obj)
  }

  useEffect(() => {
    if (isOpen) return
    if (document.activeElement && panelRef.current.contains(document.activeElement)) {
      document.activeElement.blur()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    setIsOpen(panel === gender)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panel])

  return (
    <div 
      id={`nd-panel-${gender}`} 
      className={styles.sub_head}
      aria-hidden={!isOpen}
      data-panel={`panel-${gender}`} 
      style={{visibility: `${isOpen ? 'visible' : 'hidden'}`, position: `${isOpen ? 'relative' : 'absolute'}`}}
      ref={panelRef}
    > 
      {obj.name !== 'journal' ? (
        <div className={[styles.hcontent, styles.panel].join(' ')}>
          {menu.map( (item, index) => 
            <React.Fragment key={`subcol-${gender}${index}`}>
              {item.name === 'Highlights' && (
                <ul className={[styles.sub_group, styles.features].join(' ')}>
                  {item.group_links.map( (link, index) => 
                    <li key={`sublink-${gender}${index}`}>
                      <Flink 
                        className={styles.hlink}
                        href={link.url} 
                        tabIndex={isOpen ? undefined : '-1'}
                        onClick={(e)=> handleClick(link.name, link.url)}
                      >
                        {link.name}
                      </Flink>
                    </li>
                  )}
                </ul>
              )}
              {!specials.includes(item.name) &&  (
                <ul className={styles.sub_group}>
                  {item.group_links.map( (link, index) => 
                    <li key={`sublink-${gender}${index}`}>
                      <Flink
                        className={styles.hlink}
                        href={link.url} 
                        tabIndex={isOpen ? undefined : '-1'}
                        onClick={(e)=> handleClick(link.name, link.url)}
                      >
                        {link.name}
                      </Flink>
                    </li>
                  )}
                </ul>
              )}
            </React.Fragment>
          )}
          {has_collabsfeats() && (
            <div className={styles.group_wrap}>
              {has_collabsfeats().map( (group) => 
                <ul key={`${gender}${group.name}`} className={styles.sub_group}>
                  <li>
                    <div className={styles.title}>
                      {group.name}
                    </div>
                  </li>
                  {group.group_links.map( (link,index) => 
                    <li key={`sublink-${gender}${link.name}${index}`}>
                      <Flink 
                        className={styles.hlink}
                        href={link.url} 
                        tabIndex={isOpen ? undefined : '-1'}
                        onClick={(e)=> handleClick(link.name, link.url)}
                      >
                        {link.name}
                      </Flink>
                    </li>
                  )}
                </ul>
              )}
            </div>
          )}
          {promo && (
            <div className={styles.promos}>
              {promo.group_links.map( (link, index) => 
                <div key={`promo-${gender}${index}`} className={styles.promo_item}>
                  <Flink 
                    href={link.url} 
                    tabIndex={isOpen ? undefined : '-1'}
                    onClick={(e)=> handleClick(link.name, link.url)}
                  >
                    <img 
                      className={styles.promo_img} src={link.image_url}
                      alt="" 
                      loading="lazy" 
                      width="300" 
                      height="400"
                    />
                  </Flink>
                  <p>
                    <Flink 
                      className={styles.hlink}
                      href={link.url}
                      tabIndex={isOpen ? undefined : '-1'}
                      onClick={(e)=> handleClick(link.name, link.url)}
                    >
                      {link.name}
                    </Flink>
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className={[styles.hcontent, styles.panel].join(' ')}>
          {journ_stories(obj.data).length > 0 && (
            <div className={styles.group_wrap}>
              <div className={styles.title}>STORIES</div>
              {journ_stories(obj.data).map( (group) => 
                <ul key={`${gender}${group.name}${group.gender}`} className={styles.sub_group}>
                  <li>
                    <div className={[styles.title, styles.subtitle].join(' ')}>
                      {group.gender === 'both' ? 'Buck Mason' : group.gender}
                    </div>
                  </li>
                  {group.menu.map( (link) => 
                    <li key={`sublink-${gender}${link.handle}`}>
                      <Flink 
                        className={styles.hlink}
                        href={`/blogs/journal/${link.handle}`} 
                        tabIndex={isOpen ? undefined : '-1'}
                        onClick={(e)=> handleClick(link.title, `/blogs/journal/${link.handle}`)}
                        onMouseEnter={(e) => setJournPrev(link)}
                      >
                        {link.title}
                      </Flink>
                    </li>
                  )}
                </ul>
              )}
            </div>
          )}
          {journ_other(obj.data).length > 0 && (
            <>
              {journ_other(obj.data).map( (group,index) => 
                <div key={`other${gender}${group.name}`} className={styles.group_wrap}>
                  <div className={styles.title}>{group.name}</div>
                  <ul className={styles.sub_group}>
                    {group.menu.slice(0,5).map( (link) => 
                      <li key={`sublink-${gender}${link.handle}`}>
                        <Flink 
                          className={styles.hlink}
                          href={`/blogs/journal/${link.handle}`} 
                          tabIndex={isOpen ? undefined : '-1'}
                          onClick={(e)=> handleClick(link.title, `/blogs/journal/${link.handle}`)}
                          onMouseEnter={(e) => setJournPrev(link)}
                        >
                          {link.title}
                        </Flink>
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </>
          )}
          <div className={styles.journ_prev}>
            {journPrev ? (
              <>
                {journPrev.image && (
                  <Flink href={`/blogs/journal/${journPrev.handle}`} className={styles.journ_link}>
                    <img src={imgSize(journPrev.image, 400)} alt={journPrev.title} />
                    <p>
                      {journPrev.title}
                    </p>
                  </Flink>
                )}
              </>
            ) : feat_post ? (
              <Flink href={`/blogs/journal/${feat_post.handle}`} className={styles.journ_link}>
                <img src={imgSize(feat_post.image, 400)} alt={feat_post.title} />
                <p>
                  {feat_post.title}
                </p>
              </Flink>
            ) : (
              <div className={styles.journ_place}><p>&nbsp;</p></div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default HeaderSubmenu



