import React, { useEffect, useRef } from 'react';
import { useTheme } from '~/contexts/ThemeContext'
import useKeyboardEvent from '~/utils/keyboard-evt'

import IconClose from '~/components/icons/close'

import { aria_btn, bg } from "~/styles/app.module.scss"
import * as styles from "~/styles/shared/modal.module.scss"


const Modal = ({ children, name, childStyles, onModalClose }) => {
  const { setFreeze, cartOpen } = useTheme()
  const { closeListen } = useKeyboardEvent();

  const bgRef = useRef(null)
  const modalRef = useRef(null)

  const handleClose = () => {
    if (!bgRef.current || !modalRef.current) return
      
    bgRef.current.setAttribute('aria-hidden', 'true')
    modalRef.current.setAttribute('aria-hidden', 'true')
    setTimeout(() => {
      if (!cartOpen) setFreeze(false)
      onModalClose(false)
    }, 200)
  }

  useEffect(() => {
    closeListen(handleClose)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeListen]);

  useEffect(() => {
    setFreeze(true)
    setTimeout(() => {
      if (bgRef.current) bgRef.current.setAttribute('aria-hidden', 'false')
      if (modalRef.current) modalRef.current.setAttribute('aria-hidden', 'false')
    }, 100)
  }, [setFreeze]);

  return (
    <>
      <button 
        type="button"
        className={bg} 
        aria-hidden="true" ref={bgRef} 
        onClick={(e)=> {handleClose()}} 
        aria-label={'Close modal window'}
      />
      <div 
        id={`armodal-${name}`}
        className={`${childStyles} ${styles.modal}`}
        role="dialog" 
        aria-labelledby={`armodal_lab-${name}`}
        aria-modal="true" 
        aria-hidden="true"
        ref={modalRef}
      >
        <button 
          type="button"
          className={[aria_btn, styles.close].join(' ')}
          aria-label={`Close ${name} popup`} 
          onClick={(e)=> {handleClose()}}
        >
          <IconClose icon_classes={''} />
        </button>
        <div className={styles.modal_body}>
          {children}
        </div>
      </div>
    </>
  )
}

export default Modal


