import _ from 'lodash'

const useSearch = () => {

  let curr_gender 
  let curr_page 
  let val_arr 

  const buildVals = (val, searchType) => {
    const prod_gends = [null,'m','w']
    curr_gender = prod_gends.includes(searchType) ? searchType : false
    curr_page = prod_gends.includes(searchType) ? false : searchType
    
    let new_arr = val.toLowerCase().split(' ')
    new_arr = _.map(new_arr, x => x.charAt(x.length-1) === 's' ? x.slice(0, -1) : x)
    if ( new_arr.includes('gray') ) {
      new_arr.push('grey')
    }
    const val_gends = ['wom', 'wome','women', 'men']
    if ( curr_gender === null && _.intersection(new_arr, ['wom', 'wome','women']).length  ){
      curr_gender = 'w'
    } else if ( curr_gender === null && new_arr.includes('men') ) {
      curr_gender = 'm'
    }
    
    val_arr = _.differenceWith(new_arr, val_gends)
  }

  const searchProds = (products) => {

    let prod_search = products.filter( prod => {
      const search = !_.map(val_arr, (x) => {
        const whole = prod.search.includes(x) 
        const sub_str = _.map(prod.search, y => y.includes(x)).includes(true) 
        return whole || sub_str
      }).includes(false)
      return search ? prod : false
    })

    if (curr_gender && !curr_page) {
      prod_search = _.filter(prod_search, x => x.gender === curr_gender)
    }
    if (!curr_gender && curr_page) {
      prod_search = []
    }
    prod_search = _.sortBy(prod_search, x => x.handle )
    return prod_search
  }

  const searchPages = (pages) => {
    // const pages_edited = pages.map(x => {
    //   const name_arr = [...new Set((`${x.handle}${x.add ? `-${x.add}` : ''}`).replace(' ', '-').toLowerCase().split('-'))]
    //   x['search'] = name_arr
    // })
    let page_search = pages.filter( page => {
      const search = !_.map(val_arr, x => page.search.includes(x) || _.map(page.search, y => y.includes(x)).includes(true) ).includes(false)
      return search ? page : false
    })
    if (curr_gender) {
      page_search = _.filter(page_search, x => x.gender === curr_gender)
    }
    if (!curr_gender && curr_page) {
      if ( curr_page === 'page' ) {
        page_search = _.filter(page_search, x => x.type !== 'store' && x.type !== 'collection')
      } else {
        page_search = _.filter(page_search, x => x.type === curr_page)
      }
    }
    return page_search
  }

  const saveSearch = (obj) => {
    obj['val_arr'] = val_arr
    localStorage.setItem('search', JSON.stringify(obj))

    return true
  }

  const getSearch = () => {
    if (!localStorage.getItem('search')) return false

    const obj = JSON.parse(localStorage.getItem('search'))
    val_arr = obj.val_arr

    return obj
  }

  return { buildVals, searchProds, searchPages, saveSearch, getSearch }
};

export default useSearch


// const threshold = 0.6
// let test_search = []
// let terms = []
// let erms = []

// products.forEach( prod => {
//   const direct = !_.map(val_arr, (x) => {
//     const whole = prod.search.includes(x) 
//     const sub_str = _.map(prod.search, y => y.includes(x)).includes(true) 
//     return whole || sub_str
//   }).includes(false)

//   let search = _.map(val_arr, (x) => 
//     prod.search.map(y => [compare_str(x, y), x, y] )
//   )
//   search = search.filter(x => !!x)

//   terms.push( prod.search.map(x => [dlevenshtein(val_arr[0], x), val_arr[0], x]) )
//   erms.push( prod.search.map(x => [compare_str(val_arr[0], x), val_arr[0], x] ) )

//   search.forEach(arr => {
//     const found = arr.find(y => y[0] >= threshold)
//     if (found) terms.push([found[1], found[2]])
//   })

//   // if ( direct || search.map(arr => arr.find(y => y[0] >= threshold) ? true : false).includes(true) ) {
//   //   test_search.push(prod, direct, search)
//   // }
// })

// const compare_str = (search_val, test_str) => {
//     const set1 = new Set(search_val);
//     const set2 = new Set(test_str);

//     const levDistance = levenshtein(search_val, test_str);
//     const maxLev = Math.max(search_val.length, test_str.length); // Max possible Levenshtein distance
//     const normLev = levDistance / maxLev;

//     const jaccard = jaccardIndex(set1, set2);

//     // Weighted average example: assuming Levenshtein is 70% and Jaccard is 30% of importance
//     return (0.7 * (1 - normLev)) + (0.3 * jaccard);
// }

// const levenshtein = (search_val, test_str) => {
//   const len1 = search_val.length
//   const len2 = test_str.length
//   const matrix = []

//   for (let i = 0; i <= len1; i++) {
//     matrix[i] = [i]
//   }
//   for (let j = 0; j <= len2; j++) {
//     matrix[0][j] = j
//   }

//   for (let i = 1; i <= len1; i++) {
//     for (let j = 1; j <= len2; j++) {
//       const cost = search_val[i - 1] === test_str[j - 1] ? 0 : 1;
//       matrix[i][j] = Math.min(
//         matrix[i - 1][j] + 1, 
//         matrix[i][j - 1] + 1,
//         matrix[i - 1][j - 1] + cost
//       )
//     }
//   }

//   return matrix[len1][len2]

//   // const len1 = search_val.length;
//   // const len2 = test_str.length;
//   // const matrix = Array.from({ length: len1 + 1 }, () => Array(len2 + 1));

//   // // Initialize the matrix
//   // for (let i = 0; i <= len1; i++) {
//   //     matrix[i][0] = i;
//   // }
//   // for (let j = 0; j <= len2; j++) {
//   //     matrix[0][j] = j;
//   // }

//   // // Fill the matrix
//   // for (let i = 1; i <= len1; i++) {
//   //     for (let j = 1; j <= len2; j++) {
//   //         const cost = (search_val[i - 1] === test_str[j - 1]) ? 0 : 1;
//   //         matrix[i][j] = Math.min(
//   //             matrix[i - 1][j] + 1,        // Deletion
//   //             matrix[i][j - 1] + 1,        // Insertion
//   //             matrix[i - 1][j - 1] + cost  // Substitution
//   //         );

//   //         // Check for transposition
//   //         if (i > 1 && j > 1 && search_val[i - 1] === test_str[j - 2] && search_val[i - 2] === test_str[j - 1]) {
//   //             matrix[i][j] = Math.min(matrix[i][j], matrix[i - 2][j - 2] + cost); // Transposition
//   //         }
//   //     }
//   // }

//   // return matrix[len1][len2];
// }

// const dlevenshtein = (search_val, test_str) => {
//   const len1 = search_val.length
//   const len2 = test_str.length
//   const matrix = Array.from({ length: len1 + 1 }, () => Array(len2 + 1))

//   // Initialize the matrix
//   for (let i = 0; i <= len1; i++) {
//       matrix[i][0] = i;
//   }
//   for (let j = 0; j <= len2; j++) {
//       matrix[0][j] = j;
//   }

//   // Fill the matrix
//   for (let i = 1; i <= len1; i++) {
//       for (let j = 1; j <= len2; j++) {
//           const cost = (search_val[i - 1] === test_str[j - 1]) ? 0 : 1;
//           matrix[i][j] = Math.min(
//               matrix[i - 1][j] + 1,        // Deletion
//               matrix[i][j - 1] + 1,        // Insertion
//               matrix[i - 1][j - 1] + cost  // Substitution
//           );

//           // Check for transposition
//           if (i > 1 && j > 1 && search_val[i - 1] === test_str[j - 2] && search_val[i - 2] === test_str[j - 1]) {
//               matrix[i][j] = Math.min(matrix[i][j], matrix[i - 2][j - 2] + cost); // Transposition
//           }
//       }
//   }

//   return matrix[len1][len2]
// }

// function jaccardIndex(set1, set2) {
//     const intersection = new Set([...set1].filter(x => set2.has(x)));
//     const union = new Set([...set1, ...set2]);
//     return intersection.size / union.size;
// }