import { getCookie, setCookie } from '~/utils/helpers'


const Geoloc = () => {

  const checkSaved = () => {
    const cookie = getCookie('bmloc')
    let local = (typeof localStorage !== "undefined") && localStorage.getItem('bmloc')
    if (cookie && local) {
      return JSON.parse(local)
    }
    return false
  }

  const getGeo = async (noreq=false) => {
    const minobj = {
        zip: 90046,
        country: 'US',
        ip: false,
        currStores: [],
        usrZip: null,
        usrCountry: null,
        usrCoords: null,
        pdpStore: null,
        shipIntl: false,
        storeGend: false,
        reg: 'Los Angeles California',
        coords: [34.0873221,-118.3485468]
      }

    if (getCookie('bmloc') === 0) return minobj
    if (isBot()) return minobj

    let savedNotExp = checkSaved()
    if (savedNotExp) return savedNotExp
    
    let saved = JSON.parse(localStorage.getItem('bmloc') || "{}")

    let newLoc = {}
    newLoc = await getBMip()
    if ((typeof localStorage !== "undefined") && localStorage.getItem('shiplog')) {
      console.log(newLoc)
    }

    if (!(newLoc?.country && newLoc?.coords)) {
      newLoc = await getFenix()
    }

    if (!(newLoc?.country && newLoc?.coords)) {
      newLoc = await cfBackup(newLoc)
    }
    
    const cntry = !!saved?.usrCountry ? saved.usrCountry : newLoc?.country || null
    const notUS = cntry !== 'US'

    let obj = {
      zip: newLoc?.zip || minobj.zip, 
      country: newLoc?.country || minobj.zip, 
      ip: newLoc?.ip || minobj.zip,
      currStores: saved?.currStores || [],
      usrZip: saved?.usrZip || null,
      usrCountry: saved?.usrCountry || null,
      usrCoords: saved?.usrCoords || null,
      pdpStore: saved?.pdpStore || null,
      shipIntl: saved?.shipIntl || notUS,
      storeGend: saved?.storeGend || false,
      reg: newLoc?.city ? `${newLoc?.city} ${newLoc?.region}` : minobj.reg,
      coords: newLoc?.coords || minobj.coords,
      src: newLoc?.src
    }

    setCookie('bmloc',1,30)

    return obj
  }

  const calcDistance = (coords1, coords2) => {
    const R = 3958.8;
    const lat1 = coords1[0] * Math.PI / 180;
    const lon1 = coords1[1] * Math.PI / 180;
    const lat2 = coords2[0] * Math.PI / 180;
    const lon2 = coords2[1] * Math.PI / 180;
    const dLat = lat2 - lat1;
    const dLon = lon2 - lon1;
    // Haversine formula
    const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
              Math.cos(lat1) * Math.cos(lat2) *
              Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    const distance = R * c;
    let raw_dist = distance.toFixed(2);
    raw_dist = raw_dist > 100 ? Math.round(raw_dist) : parseFloat(raw_dist);
    return raw_dist;
  }


  // Device location ------------------------------------------------

  const requestDeviceAccess = () => {
    return new Promise((resolve, reject) => {
      if (!('geolocation' in navigator)) {
        resolve(false)
        return
      }
      try {
        navigator.geolocation.getCurrentPosition(
          position => {
            resolve(position.coords)
          },
          error => {
            resolve(false)
          },
          {
            enableHighAccuracy: false,
            timeout: 3000,
            maximumAge: 60000
          }
        );
      } catch (e) {
        resolve(false)
      }
    });
  }

  const getDevice = async () => {
    try {
      const coords = await requestDeviceAccess();
      if (!(coords?.longitude && coords?.latitude)) return null
      
      const result = await openDataCoords(coords);
      return result
    } catch (error) {
      return null
    }
  }

  
  // BM - IP lookup -------------------------------------------------
  // trying out but with fenix backup

  const getBMip = async () => {
    const headers = {
      'Accept': 'application/json',
      "Content-Type": "application/json"
    }
    // if (process.env.NODE_ENV !== "production" ) {
    //   headers['X-API-Key'] = process.env.LOCAL_LOC_KEY
    // }

    let loc = {}

    try {
      const req = await fetch('https://loc.buckmason.com/api', {
        method: "POST",
        headers: headers,
        body: JSON.stringify({})
      })
      const data = await req.json()
      loc = {
        zip: data.postal_code, 
        city: data.city, 
        region: data.region, 
        country: data.country_code, 
        ip: data.ip_address, 
        coords: data.coords,
        src: 'bmip'
      }

    } catch (error) {
      console.error('loc error')
    } 
    return new Promise((resolve, reject) => resolve(loc))
  }

  // BM - ZIP lookup -------------------------------------------------
  // not a complete list of all post codes, but ok as a backup to opendata

  // const bmZip = async (zip) => {
  //   const headers = {
  //     'Accept': 'application/json',
  //     "Content-Type": "application/json"
  //   }
  //   if (process.env.NODE_ENV !== "production" ) {
  //     headers['X-API-Key'] = process.env.LOCAL_LOC_KEY
  //   }
  //   const req = await fetch('https://loc.buckmason.com/api', {
  //     method: "POST",
  //     headers: headers,
  //     body: JSON.stringify({post: zip})
  //   })
  //   let data = await req.json()
  //   let loc = {
  //     zip: zip,
  //     city: data?.city,
  //     region: data?.region,
  //     coords: data?.coords,
  //     country: data?.country_code
  //   }

  //   return new Promise((resolve, reject) => {
  //     resolve(loc)
  //   })
  // }


  // Query open data DB ---------------------------------------------
  // https://data.opendatasoft.com/explore/dataset/geonames-postal-code%40public/api/?flg=en-us

  const openDataZip = async (zip, coords) => {
    let post_code = zip.toUpperCase()
    if (post_code.includes(' ')) post_code = post_code.split(' ')[0]
    if (post_code.includes('-')) post_code = post_code.split('-')[0]

    const query = `where=postal_code="${post_code}" and (country_code="US" or country_code="CA")`
    const data = await getOpenData(query)
    return data
  }

  const openDataCoords = async (coords) => {
    // https://data.opendatasoft.com/explore/dataset/geonames-postal-code%40public/api/?flg=en-us

    const query = `where=within_distance(coordinates, geom'POINT(${coords.longitude} ${coords.latitude})', 3km)`
    const data = await getOpenData(query)
    return data
  }

  const getOpenData = async (query) => {
    const res = await fetch(`https://data.opendatasoft.com/api/explore/v2.1/catalog/datasets/geonames-postal-code@public/records?${query}&limit=20`)
    const data = await res?.json()

    if (data?.results?.length > 0) {
      const first = data.results[0]
      const loc = {
        zip: first?.postal_code,
        city: first?.place_name,
        region: first?.admin_name1,
        coords: [first?.latitude, first?.longitude],
        country: first?.country_code
      }

      return loc
    }
    
    return {}
  }


  // Fenix backup ---------------------------------------------------

  const getFenix = async () => {
    return new Promise((resolve, reject) => { 
      fetch('https://ipapi.co/json/?key=Jaio0rg93RS6UPxzEXB19vvw4NKUb292e9pLeiDOjsfnSjyLc5', {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then(req => {
        if (req.status !== 200) resolve({})
        return req.json()
      })
      .then(data => {
        resolve({
          zip: data.postal, 
          city: data.city, 
          region: data.region, 
          country: data.country, 
          ip: data.ip, 
          coords: [data.latitude, data.longitude],
          src: 'fenix'
        })
      })
    })
  }

  // Cloudflare + airport backup ------------------------------------
  // last chance for loc data

  const cfBackup = async (newLoc) => {
    let cft = await getCFdata()
    if (cft?.colo) {
      const airport = await airportCoords(cft?.colo)
      if (airport) {
        newLoc.coords = [airport.latitude, airport.longitude]
        const openloc = await openDataCoords(airport)
        newLoc.city = openloc?.city
        newLoc.region = openloc?.region
        newLoc.zip = openloc?.zip
        newLoc.src = 'cf'
      }
    }
    newLoc.country = cft?.loc

    return newLoc
  }

  const getCFdata = () => {
    return new Promise((resolve, reject) => {
      fetch('https://www.cloudflare.com/cdn-cgi/trace')
      .then((response) => response.text())
      .then((data) => {
        const cfdata = data.trim().split('\n').reduce((obj, pair) => {
          pair = pair.split('='); // Split the pair into key and value
          obj[pair[0]] = pair[1]; // Assign the value to the object
          return obj; // Return the object
        }, {})
        resolve(!!cfdata?.colo ? cfdata : {})
      })
    })
  }

  const airportCoords = async (airport) => {
    try {
      const searchResponse = await fetch(
        `https://en.wikipedia.org/w/api.php?action=query&list=search&srsearch=${airport}%20airport&format=json&origin=*`
      );
      const searchData = await searchResponse.json();
      if (searchData && searchData.query && searchData.query.search && searchData.query.search.length > 0) {
        const pageId = searchData.query.search[0].pageid;
        
        const contentResponse = await fetch(
          `https://en.wikipedia.org/w/api.php?action=query&prop=extracts|coordinates&exintro=1&explaintext=1&pageids=${pageId}&format=json&origin=*`
        );
        const contentData = await contentResponse.json();
        
        if (contentData && contentData.query && contentData.query.pages && contentData.query.pages[pageId]) {
          const page = contentData.query.pages[pageId];
          const coordinates = page.coordinates ? page.coordinates[0] : null;
          
          if (coordinates.lat && coordinates.lon) {
            return {latitude: coordinates.lat, longitude: coordinates.lon}
          }
        }
      }
      return {}
    } catch (error) {
      return {}
    }
  }

  const mapObj = async (zip, country='us') => {
    let post_code = zip.toUpperCase()
    if (post_code.includes(' ')) post_code = post_code.split(' ')[0]
    if (post_code.includes('-')) post_code = post_code.split('-')[0]

    let loc = {zip: post_code}

    loc = await openDataZip(zip)

    if (post_code?.length >= 5 && !loc?.coords && !loc?.country) {
      const req = await fetch(`https://api.zippopotam.us/${country.toLowerCase()}/${post_code}`) 
      let json = await req.json()
      loc = {zip: post_code}
      if (json?.places && json.places.length > 0) {
        const place = json.places[0]
        loc = {
          ...loc,
          city: place['place name'],
          state: place['state abbreviation'],
          country: json['country abbreviation'],
          coords: [place?.latitude, place?.longitude]
        }
      } else {
        loc = await googleZip(post_code)
      }
    }

    return new Promise((resolve, reject) => {
      resolve(loc)
    })
  }

  const googleZip = (zip) => {
    return new Promise((resolve, reject) => {
      new window.google.maps.Geocoder().geocode({address: zip}, (res, status) => {
        const has_zip = res && res.find(item => item.address_components.find( x => x.types.includes("postal_code")) )
        if (status === window.google.maps.GeocoderStatus.OK && has_zip) {
          const loc = res.find(item => item.address_components.find( x => x.types.includes("postal_code")) )
          let city = loc.address_components.find( x => x.types.includes('locality'))
          if (!city) city = loc.address_components.find( x => x.types.includes('neighborhood'))
          const coords = [loc.geometry.location.lat(),loc.geometry.location.lat(),loc.geometry.location.lng()]
          const country = loc.address_components.find( x => x.types.includes('country'))
          const state = loc.address_components.find( x => x.types.includes('administrative_area_level_1'))
          resolve({
            zip: zip,
            city: city?.long_name,
            state: state?.short_name,
            coords: coords,
            country: country?.short_name
          })
        } else {
          resolve({})
        }
      })
    })
  }

  const doStoresSort = (items) => {
    return items.sort((a, b) => {
      if (a.distance === false && b.distance !== false) return 1;
      if (a.distance !== false && b.distance === false) return -1; 
      if (a.distance === false && b.distance === false) return 0;
      return a.distance - b.distance; 
    })
  }

  const getDistances = (regions, locate=false, store_coords=false) => {
    const new_regions = [...regions]
    let coords = false
    if (store_coords) {
      coords = store_coords 
    } else if (locate) {
      coords = locate?.usrCoords || locate?.coords
    }

    if (!coords) return  doStoresSort(new_regions)

    new_regions.forEach( region => {
      let shortest = false
      region.stores.forEach( store => {
        if (!store.coordinates) {
          store.distance = false
          return
        }

        const distance = calcDistance(coords, store.coordinates)
        let raw_dist = distance > 100 ? Math.round(distance) : parseFloat(distance)
        store.distance = raw_dist
        if (raw_dist < shortest || !shortest) shortest = raw_dist
      })
      region.distance = shortest
      region.stores = doStoresSort(region.stores)
    })

    return doStoresSort(new_regions)
  }

  const isBot = () => {
    if (typeof navigator === 'undefined') return true

    const userAgent = navigator.userAgent.toLowerCase();
    const botPatterns = [
      'bot', 'crawl', 'spider', 'slurp', 'baiduspider', 
      'yandex', 'google', 'bingbot', 'semrush',
      'lighthouse', 'pingdom', 'headless', 'prerender', 
      'screaming', 'phantom', 'selenium', 'chrome-lighthouse',
      'googlebot', 'adsbot', 'mediapartners', 'ahrefsbot'
    ]

    const isUserAgentBot = botPatterns.some(pattern => 
      userAgent.includes(pattern)
    )

    const hasNoWebGLSupport = () => {
      try {
        const canvas = document.createElement('canvas');
        return !(canvas && canvas.getContext && canvas.getContext('webgl'))
      } catch (e) {
        return true
      }
    }
    return isUserAgentBot || hasNoWebGLSupport()
  }




  return { checkSaved, getGeo, calcDistance, getDistances, mapObj, getDevice, isBot }
};

export default Geoloc;






