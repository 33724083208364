import { sendShopifyAnalytics, getClientBrowserParameters,
  AnalyticsEventName, AnalyticsPageType } from '@shopify/hydrogen-react';
import { useTheme } from '~/contexts/ThemeContext';
import { deShopId, imgCDN } from '~/utils/helpers'


const useAnalytics = () => {
  const { analytics, customer, cart, shiplog } = useTheme()

  if ( typeof window !== 'undefined' ) {
    window.dataLayer = window.dataLayer || []
    window.ElevarDataLayer = window.ElevarDataLayer || []
    window.liQ = window.liQ || []
  }


  const analyticsShopData = {
    shopId: `gid://shopify/Shop/${process.env.NODE_ENV === "production" ? '12350652473' : '12775358521'}`,
    currency: 'USD',
    acceptedLanguage: 'en'
  };

  const hasUserConsent = true
  
  const page_send = ( pageType ) => {
    if (process.env.GATSBY_CURR_ENV === "production_local") return
    const obj = {
      pageType: AnalyticsPageType[pageType],
      hasUserConsent,
      ...analyticsShopData,
    };

    sendPageView(obj)
  };

  const sendPageView = async (analyticsPageData) => {
    if (process.env.GATSBY_CURR_ENV === "production_local") return

    const payload = {
      ...getClientBrowserParameters(),
      ...analyticsPageData,
    };

    if (process.env.NODE_ENV !== "production") {
      payload.url = payload.url.replace('http://localhost:8000','https://staging.buckmason.com')
    }

    const shop_url = process.env.NODE_ENV === "production" ? 'buck-mason-usa.myshopify.com' : 'buck-mason-staging.myshopify.com'

    try {
      const result = await sendShopifyAnalytics({
        eventName: AnalyticsEventName.PAGE_VIEW,
        payload,
      }, shop_url);
      
      if (shiplog) {
        console.log('Shopify Analytics event sent successfully:', {
          eventName: AnalyticsEventName.PAGE_VIEW,
          payload,
          result
        })
      }
    } catch (error) {
      if (shiplog) console.error('Failed to send Shopify Analytics event:', error);
    }
  }

  // const sendCart = (analyticsPageData) => {
  //   if (process.env.GATSBY_CURR_ENV === "production_local") return
    
  //   const payload = {
  //     ...getClientBrowserParameters(),
  //     ...analyticsPageData,
  //   };

  //   if (process.env.NODE_ENV !== "production") {
  //     payload.url = payload.url.replace('http://localhost:8000','https://staging.buckmason.com')
  //   }
  //   const shop_url = process.env.NODE_ENV === "production" ? 'buck-mason-usa.myshopify.com' : 'buck-mason-staging.myshopify.com'

  //   sendShopifyAnalytics({
  //     eventName: AnalyticsEventName.ADD_TO_CART,
  //     payload,
  //   }, shop_url);
  // }

  const set_cookie = (name, value, days) => {
    if (typeof document === 'undefined') return
    const is_local = window.location.hostname.includes('localhost')
    
    let expires = ''
    if (days) {
      let date = new Date()
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
      expires = `; expires=${date.toUTCString()}`
    }
    document.cookie = `${name}=${(value || '')}${expires}; path=/;${is_local ? '' : ' domain=buckmason.com'}`
  }

  const get_cookie = (name) => {
    if (typeof document === 'undefined') return null

    const nameEQ = `${name}=`
    var ca = document.cookie.split(';')
    for(var i=0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1,c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length)
    }
    return null
  }



  // --------------------------------------------------------------------------
  // ANALYTICS EVENTS ---------------------------------------------------------
  // --------------------------------------------------------------------------


  // product click
  // cart add
  // cart remove
  

  const ana_home = () => {
    window.dataLayer.push({pageType: 'home'})

    gadl_loaded('home page', localStorage.getItem('homeg'))
    ga_customer()
    elev_customer()
    ga_remarketing('home')
    li_page('viewLandingPage', 'homepage')
  }

  const ga_page = () => {
    window.dataLayer.push({pageType: 'other'})
    const title = document.title.replace(' – Buck Mason','').replace(' - Staging','').replace("'",'').toLowerCase()

    gadl_loaded(`page: ${title}`, '')
    ga_remarketing('page')
    li_page(li_page_type(window.location.pathname), window.location.pathname)
  }


  const gadl_loaded = (type, category, reviews=false) => {
    // if ( clear && (typeof window.ElevarInvalidateContext !== 'undefined')) window.ElevarInvalidateContext()
    let cust_auth = customer ? 'logged in' : 'anonymous'
    let cust_id = customer ? customer.id.replace('gid://shopify/Customer/','') : ''

    const obj = {
      event: "dle_globalDataLoaded",
      page: {
        category: category, 
        type: type
      },
      site: {
        environment: process.env.NODE_ENV === "development" ? 'staging': 'production',
      },
      user: {
        authStatus: cust_auth,
        id: cust_id
      } 
    }

    if (reviews) {
      obj.page['reviews'] = reviews
    }

    window.dataLayer.push(obj)
  }

  const ga_remarketing = (type) => {
    const obj = {
      event: 'Remarketing',
      tagParams: {}
    }

    if ( type === 'home' ) {
      obj.tagParams = {
        ecomm_pagetype: 'home'
      }
    }
    else if ( type === 'plp' ) {
      obj.tagParams = {
        ecomm_pagetype: 'category'
      }
    }
    else if ( type === 'pdp' ) {
      const ids = pdp.variants.map(x => `shopify_US_${deShopId(pdp.shop_id)}_${x.id}`)
      obj.tagParams = {
        ecomm_pagetype: 'product',
        ecomm_prodid: ids,
        ecomm_totalvalue: parseFloat(pdp.first_product_price)
      }
    }
    else {
      obj.tagParams = {
        ecomm_pagetype: 'other'
      }
    }
    window.dataLayer.push(obj)
  }

  const li_page_type = (path) => {
    return path.includes('/blogs/') ? 'viewArticle' : 'viewLandingPage'
  }

  const li_page = (type, path) => {
    // const obj = {
    //   event: 'viewContent',
    //   contentType: type,
    //   email: customer ? customer.email : '',
    //   contentId: path.toString()
    // }

    // window.liQ.push(obj)
  }

  const ga_customer = () => {
    let obj

    if ( customer ) {
      const cust_id = customer.id.replace('gid://shopify/Customer/','')
      obj = {
        visitorType: "logged in",
        visitorId: cust_id,
        CustomerId: cust_id,
        CustomerEmail: customer.email,
        CustomerFirstName: customer.firstName,
        CustomerLastName: customer.lastName,
        CustomerOrdersCount: customer.numberOfOrders,
        CustomerTotalSpent: customer.totalAmount,
      }
    } else {
      obj = {
        visitorType: "guest"
      }
    }
    window.dataLayer.push(obj)
  }

  const elev_customer = () => {
    const el_obj = {
      event: 'dl_user_data',
      cart_total: cart ? cart.estimatedCost.subtotalAmount.amount : '0.00',
      user_properties: elev_user(),
      ecommerce: {
        currencyCode: 'USD',
        cart_contents: {
          products: obj_elev_cart()
        }
      }
    }

    window.ElevarDataLayer.push(el_obj)
  }



  // PLP ANALYTICS ------------------------------------------------------------
  // --------------------------------------------------------------------------


  const ana_plp = (products, handle, first_load=false) => {
    if (typeof window.ElevarInvalidateContext !== 'undefined') window.ElevarInvalidateContext()
    window.dataLayer.push({pageType: 'category'})
    window.dataLayer.push({ ecommerce: null });
    
    if (first_load) {
      ga_customer()
      elev_customer()
      gadl_loaded('category', handle)
    }
    ga_plp(products, handle)
    elev_plp(products, handle)
    if (first_load) ga_remarketing('plp')

    li_plp(products)
  }

  const ga_plp = (products, handle) => {
    // Ecommerce - Product List Impression
    // Executed when a user views a product found in a list, such as on a:
    // - product category page
    // - product upsell
    // - recommended products

    // The data layer event should follow the same format currently used for 
    // collection view events. The only required update should be to extend the 
    // usage of the event so it also covers product upsells and recommended products. 
    // The detection of these additions should be applied to the actionField.list value.

    const is_vint = products.find(x => x.is_vintage)

    let visible_tiles = products.slice(0,36).map((x, index) => {
      
      const shop_id = deShopId(x.shop_id)

      let prod_obj
      if ( x.pl_handle === "product-info" ) {
        prod_obj = {
          name: (`PK Card - ${x.title || x.handle}`).toLowerCase(),
          id: shop_id,
          sku: 'pkcard',
          productId: shop_id,
          dimension2: '',
          variantId: '',
          shopifyId: `shopify_US_${shop_id}`,
          price: '',
          list: handle,
          brand: 'buck bason',
          position: index.toString(),
          category: 'pk card',
        }
      } else {
        prod_obj = {
          name: x.title ? (x.title).toLowerCase() : '',
          id: shop_id,
          sku: shop_id,
          productId: shop_id,
          dimension2: x.gender === 'm' ? 'mens' : x.gender === 'w' ? 'womens' : 'unisex',
          variantId: x.variants[0] ? x.variants[0].id : '',
          shopifyId: `shopify_US_${x.id}_${x.variants[0] ? x.variants[0].id : ''}`,
          price: parseFloat(x.first_product_price),
          list: handle,
          brand: 'buck mason',
          position: index.toString(),
          category: is_vint ? 'vintage' : x.filters.type ? x.filters.type[0] : ''
        }
      }
      return prod_obj
    })

    const obj = {
      event: 'collectionView',
      actionField: {
        list: handle
      },
      ecommerce: {
        currencyCode: 'USD',
        impressions: visible_tiles,
      }
    }

    ga_push_event(obj, true)
  }

  const elev_plp = (products, handle) => {
    let el_imprs = products.slice(0,36).map((x, index) => obj_elev_prod(x, false, 'list', {list: `/collections/${handle}`, index: index  + 1}))
    set_cookie('lastlist', `/collections/${handle}`,1)

    const obj = {
      event: "dl_view_item_list",
      user_properties: elev_user(),
      ecommerce: {
        currencyCode: 'USD',
        impressions: el_imprs
      }
    }

    window.ElevarDataLayer.push(obj)
  }

  const li_plp = (products) => {
    // const ids = products.filter(x => !!x && x.hasOwnProperty('shop_id')).map(x => x.shop_id.toString())
    // window.liQ.push({
    //   event: 'viewCategory',
    //   email: customer ? customer.email : '',
    //   itemIds: ids
    // })
  }



  const ga_plp_filter = (filters, count) => {
    // Category Page Filtering
    // Executed when a user applies filters to the category page being viewed. 

    const all_str = filters.map(x => `${x.type}: ${x.name}`)

    const obj = {
      event: "dle_categoryFilter",
      filterInfo: {
        allApplied: all_str,
        mostRecent: sessionStorage.getItem('lastfilt'),
        region: 'filter panel',
        resultCount: count
      }
    }

    sessionStorage.removeItem('lastfilt')
    window.dataLayer.push(obj)
  }



  const ga_plp_sort = (sort_type, count) => {
    // Category Page Sorting
    // Executed when a user changes the sorting method on a category page.

    let sort_name 
    switch (sort_type) {
      case 'asc':
        sort_name = 'price low to high'
        break;
      case 'desc':
        sort_name = 'price high to low'
        break;
      case 'rec':
        sort_name = 'recent'
        break;
      case 'best':
        sort_name = 'best sellers'
        break;
      case 'perc':
        sort_name = 'percent off'
        break;
      default:
        sort_name = ''
    }

    const obj = {
      event: "dle_categorySort",
      sortInfo: {
        method: sort_name,
        resultCount: count
      }
    }

    window.dataLayer.push(obj)
  }



  // PDP ANALYTICS ------------------------------------------------------------
  // --------------------------------------------------------------------------


  let pdp

  const ana_pdp = (product, curr_variant) => {
    if (typeof window.ElevarInvalidateContext !== 'undefined') window.ElevarInvalidateContext()
    window.dataLayer.push({pageType: 'product'})
    window.dataLayer.push({ ecommerce: null });

    pdp = product

    const reviews = {
      count: parseInt(product.okendo_count), 
      rating: parseFloat(product.okendo_avg)
    }

    ga_customer()
    elev_customer()
    gadl_loaded('product detail', '', reviews)
    ga_pdp(false, curr_variant)
    elev_pdp(curr_variant)
    ga_remarketing('pdp')
    li_page('viewProduct', product.shop_id)
  }

  // Ecommerce - Product Detail View
  // Executed when a user views a product detail page or clicks the Quick Add product tile.
  // *The main change to the current productDetailView event is the addition of the 
  // ecommerce.detail.actionField.listId element. This should populate with “product detail” or
  
  const ga_pdp = (quick, curr_variant) => {
    const is_vint = pdp.is_vintage
    const shop_id = deShopId(pdp.shop_id)

    const obj = {
      event: 'productDetailView',
      ecommerce: {
        currencyCode: 'USD',
        detail: {
          actionField: {
            list: analytics.list || '',
            listId: quick ? 'quick view' : 'product detail'
          },
          products: [{
            name: pdp.title ? pdp.title.replace("'", '') : pdp.handle,
            id: shop_id,
            sku: shop_id,
            productId: shop_id,
            dimension2: is_vint ? pdp.gender : pdp.filters.gender[0],
            shopifyId: `shopify_US_${shop_id}_${pdp.variants[0] ? pdp.variants[0].id : ''}`,
            price: parseFloat(pdp.first_product_price),
            list: analytics.list || '',
            brand: 'buck mason',
            category: is_vint ? `vintage: ${pdp.category}` : pdp.filters.type ? pdp.filters.type[0] : ''
          }]
        }
      }
    }

    if ( curr_variant ) {
      obj.ecommerce.detail.products[0]['variant'] = curr_variant.title ? curr_variant.title.replace("'", '') : ""
      obj.ecommerce.detail.products[0]['variantId'] = (curr_variant.id || "")
    }

    window.dataLayer.push(obj)

    if ( curr_variant ) {
      const var_obj = {
        VariantCompareAtPrice: parseFloat(pdp.first_full_price),
        VariantPrice: parseFloat(pdp.first_product_price),
        VariantInventoryQuantity: curr_variant.available,
        shopifyProductId: `shopify_US_${shop_id}_${curr_variant.id || ""}`
      }
      window.dataLayer.push(var_obj)
    }
  }

  const elev_pdp = (curr_variant) => {
    const is_vint = pdp.is_vintage
    const list = analytics.list ? analytics.list : ''

    const obj = {
      event: 'dl_view_item',
      user_properties: elev_user(),
      ecommerce: {
        currencyCode: 'USD',
        detail: {
          actionField: {
            list: is_vint ? `/collections/${pdp.vint_handle}` : list, 
            action: 'detail'
          },
          products: [obj_elev_prod(pdp, curr_variant, 'pdp')]
        }
      }
    }

    window.ElevarDataLayer.push(obj)
  }

  const ga_quick = (product) => {
    if (typeof window.ElevarInvalidateContext !== 'undefined') window.ElevarInvalidateContext()
    window.dataLayer.push({pageType: 'product'})

    const reviews = {
      count: product.okendo_count ? parseInt(product.okendo_count) : '', 
      rating: product.okendo_avg ? parseFloat(product.okendo_avg) : ''
    }

    gadl_loaded('product detail', '', reviews)
    // ga_remarketing('pdp')
  }



  // ELEVAR OBJ CREATION ------------------------------------------------------
  // --------------------------------------------------------------------------


  // check cart events are passing correctly on events
  // check to make sure that all the fields are there
  // quick buy issue
  // add additional customer fields (might need to grab from shop API)

  const obj_elev_cart = () => {
    if (!cart) return []

    let local_prods = localStorage.getItem('cprods') ? JSON.parse(localStorage.getItem('cprods')) : []

    const cart_prods = cart.lines.edges.map(item => {
      const line = item.node
      const merch = line.merchandise
      const prod = merch.product
      const shop_id = deShopId(prod.id)

      const local_prod = local_prods.find(x => x.shop_id === shop_id)

      const is_gift = prod.handle.includes('gift')
      const price = parseFloat(merch.priceV2.amount)
      const max_price = parseFloat(prod.compareAtPriceRange.maxVariantPrice.amount)
      const sale = price < max_price

      const obj = {
        id: merch.sku,
        name: prod.title,
        brand: 'buck mason',
        category: local_prod ? local_prod.category : '',
        variant: merch.title,
        price: is_gift ? `${merch.title.replace('$','')}.00` : price.toFixed(2).toString(),
        compare_at_price: (is_gift || !sale) ? '0.0' : max_price.toFixed(2).toString(),
        product_id: prod.id.replace('gid://shopify/Product/',''),
        variant_id: merch.id.replace('gid://shopify/ProductVariant/','').toString(),
        image: merch.image ? merch.image.url.replace('https:','').replace('http:','') : '',
        quantity: line.quantity.toString()
      }

      return obj
    })

    return cart_prods
  }

  const obj_elev_prod = (prod, curr_variant, view=false, opts=false) => {
    const is_gift = prod.handle.includes('gift')
    const shop_id = deShopId(prod.shop_id)
    const list = opts.list ? opts.list : analytics.list ? analytics.list : ''
    let sku = ''
    if (curr_variant || (prod.variants && prod.variants.length > 0)) {
      sku = (is_gift && curr_variant) ? `${prod.handle}-${curr_variant.title.replace('$','')}.0` : prod.variants ? prod.variants[0].sku : ''
      if (is_gift && !curr_variant) sku = `${prod.handle}-${prod.variants[0].title.replace('$','')}.0`
    }
    if (!sku && prod.sku) sku = prod.sku
    let image = prod.image
    if (!image && prod.media.length > 0) {
      image = prod.media.find(x => x.type === 'IMAGE') ? prod.media.find(x => x.type === 'IMAGE').src : null
    } 
    image = image ? imgCDN(image) : ''
    const is_vint = prod.is_vintage

    if ( prod.pl_handle === "product-info" ) {
      const pk_obj = {
        id: shop_id,
        name: (`PK Card - ${prod.title || prod.handle}`),
        brand: 'buck mason',
        category: 'pk card',
        variant: '',
        price: '0.0',
        compare_at_price: '0.0',
        product_id: shop_id,
        variant_id: '',
        list: list,
        position: opts.index,
        image: image ? image.replace('https:','').replace('http:','') : ''
      }

      return pk_obj
    }

    let price = ''
    if (is_gift && curr_variant) {
      price = `${curr_variant.title.replace('$','')}.00`
    } else if (!is_gift && prod.first_product_price) {
      price = prod.first_product_price.toFixed(2).toString()
    }

    let compare = '0.0'
    if (is_gift || !(prod.first_product_price < prod.first_full_price)) {
      compare = '0.0' 
    } else if (prod.first_full_price) {
      compare = prod.first_full_price.toFixed(2).toString()
    }

    const obj = {
      id: sku,
      name: prod.title,
      brand: 'buck mason',
      category: is_gift ? 'gift card' : is_vint ? `vintage: ${prod.category}` : prod.category ? prod.category: '',
      variant: curr_variant ? curr_variant.title : '',
      price: price,
      compare_at_price:  compare,
      product_id: shop_id,
      variant_id: curr_variant ? curr_variant.id.toString() : '',
      image: image ? image.replace('https:','').replace('http:','') : ''
    }

    if (is_vint) {
      obj.id = curr_variant.id
      obj.variant = 'one size'
    }
    if (view === 'cart') {
      obj['quantity'] = opts.qty.toString()
      obj['list'] = opts.list
      obj['position'] = opts.index
    }
    if (view === 'list') {
      obj['list'] = list
      obj['position'] = opts.index
    }
    if (view === 'select') {
      obj['list'] = list
      obj['position'] = opts.index
    }
    if (view === 'pdp') {
      obj['list'] = list
      if (is_vint && !list) obj['list'] = `/collections/${prod.vcoll_handle}`
    }
    if (view === 'cart_upd') {
      obj['quantity'] = opts.qty.toString()
      obj['list'] = list
    }

    return obj
  }

  const elev_user = () => {
    let obj = {
      visitor_type: customer ? 'logged_in' : 'guest',
      user_consent: ''
    }

    if (customer) {
      // const has_addy = customer.addresses.length > 0 && !!customer.defaultAddress.address1
      const def_addy = customer.defaultAddress

      const cust_obj = {
        customer_id: customer.id.replace('gid://shopify/Customer/',''),
        customer_email: customer.email,
        customer_first_name: customer.firstName,
        customer_last_name: customer.lastName,
        customer_order_count: customer.numberOfOrders,
        customer_total_spent: customer.totalAmount.toString(),
        customer_tags: customer.tags,
        customer_address_1: def_addy ? def_addy.address1 : '',
        customer_address_2: def_addy ? def_addy.address2 : '',
        customer_city: def_addy ? def_addy.city : '',
        customer_province: def_addy ? def_addy.province : '',
        customer_province_code: def_addy ? def_addy.provinceCode : '',
        customer_zip: def_addy ? def_addy.zip : '',
        customer_country: def_addy ? def_addy.country : '',
        customer_phone: def_addy ? def_addy.phone : ''
      }
      obj = {...obj, ...cust_obj}
    }

    return obj
  }



  // CART EVENTS --------------------------------------------------------------
  // --------------------------------------------------------------------------


  const ana_cart_add = (product, variant, quick) => {
    // Ecommerce - Add to Cart
    // Executed when a user adds a product to their cart, or increases the quantity 
    // of a product that is already in their cart.

    const itemPrice = parseFloat(product.first_product_price)
    const category = product.is_vintage ? 'vintage' : product.category ? product.category : ''

    const exp_local = !!localStorage.getItem('ctd') && (parseInt(localStorage.getItem('ctd')) < Date.now() - 86400000)
    if (exp_local) {
      localStorage.removeItem('cprods')
      localStorage.removeItem('ctd')
    }

    let cart_products = localStorage.getItem('cprods') ? JSON.parse(localStorage.getItem('cprods')) : []
    if (!cart_products.find(x => x.shop_id === product.shop_id)) {
      cart_products.push({
        available: product.available,
        category: category,
        filters: product.filters,
        first_full_price: product.first_product_price,
        first_product_price: product.first_product_price,
        handle: product.handle,
        is_vintage: product.is_vintage,
        media: product.image ? [product.image] : product.media.find(x => x.type === 'IMAGE') ? [product.media.find(x => x.type === 'IMAGE').src] : '',
        pl_handle: product.pl_handle,
        pl_name: product.pl_name,
        shop_id: product.shop_id,
        title: product.title,
        variants: product.variants,
        vcoll_handle: product.vcoll_handle,
        vcoll_title: product.vcoll_title,
      })
      localStorage.setItem('cprods', JSON.stringify(cart_products))
      if (!localStorage.getItem('ctd') || exp_local) localStorage.setItem('ctd',Date.now())
    }

    const obj = {
      event: "add_to_cart",
      currency: 'USD',
      ecomm_totalvalue: itemPrice, 
      items: [{
        brand: 'buck mason', 
        category: category,
        id: product.shop_id, 
        name: product.title ? product.title.replace("'",'').toLowerCase() : product.handle,
        price: itemPrice,
        quantity: 1, 
        variant: variant.id,
        list_name: quick ? 'quick view' : 'product detail'
      }]
    }

    ga_push_event(obj, true)

    const bm_evt = {
      event: 'bm_event',
      eventCategory: 'bm_testing',
      eventAction: 'bmtest_add_cart',
      eventLabel: '',
      conversionValue: itemPrice,
    }

    ga_push_event(bm_evt)

    elev_cart_upd('dl_add_to_cart', product, variant, 1)
    li_cart_add(product)

    window._conv_q = window._conv_q || []
    window._conv_q.push(["pushRevenue", parseFloat(itemPrice), 1, "100449709", "force_multiple"])
  }


  const ana_cart_remove = async (prod, variant, change_qty) => {
    // Ecommerce - Remove From Cart
    // Executed when a user removes a product from their cart, or decreases the quantity 
    // of a product that is already in their cart.

    let itemPrice = parseFloat(prod.first_product_price)
    let title = prod.title ? prod.title.replace("'",'').toLowerCase() : prod.handle
    let category = prod.is_vintage ? 'vintage' : prod.category ? prod.category : ''

    const obj = {
      event: "remove_from_cart",
      currency: 'USD',
      ecomm_totalvalue: parseFloat(change_qty * itemPrice), 
      items: [{
        brand: 'buck mason', 
        category: category,
        id: prod.shop_id, 
        name: title,
        price: parseFloat(itemPrice), 
        quantity: change_qty, 
        variant: variant.id 
      }]
    }

    ga_push_event(obj, true)
    elev_cart_upd('dl_remove_from_cart', prod, variant, change_qty.toString())
  }



  const elev_cart = async () => {
    // Ecommerce - Product Detail View
    // Executed when a user views a product detail page or clicks the Quick Add product tile.

    const cart = await obj_elev_cart()

    let cart_total = cart.map(x => parseFloat(x.price) * parseInt(x.quantity))
    cart_total = cart_total.reduce((total, x) => total + x, 0)

    if (cart_total === 0) return

    const obj = {
      event: 'dl_view_cart',
      user_properties: elev_user(),
      cart_total: cart_total.toFixed(2).toString(),
      ecommerce: {
        currencyCode: 'USD',
        actionField: {
        },
        impressions: cart,
      }
    }

    window.ElevarDataLayer.push(obj)
  }


  const get_list = (prod) => {
    let list = prod.is_vintage ? `/collections/${prod.vcoll_handle}` : ''
    
    if (!prod.is_vintage) {
      list = analytics.list ? analytics.list : get_cookie('lastlist') ? get_cookie('lastlist') : ''
    }
    return list
  }

  const get_cart_list = () => {
    let clist = get_cookie('clist')
    if (!clist) return []

    clist = clist.split(',')
    clist = clist.map(x => x.split(':'))
    return clist
  }

  const set_cart_list = (var_id, list) => {
    let clist = get_cart_list()
    
    if (!clist.find(x => x[0] === var_id.toString())) {
      clist.push([var_id,list])
    }

    if (clist.length < 1) return
    clist = clist.map(x => x.join(':'))
    clist = clist.join(',')
    set_cookie('clist', clist, 1)
  }


  const elev_cart_upd = async (type, prod, variant, change_qty) => {
    let list 
    if (type === 'dl_add_to_cart') {
      list = get_list(prod)
      set_cart_list(variant.id, list)

    } else {
      const clist = get_cart_list()
      const item = clist.find(x => x[0] === variant.id.toString()) || false
      list = item ? item[1] : ''
    }

    let prod_opts = {
      qty: change_qty, 
      list: list
    }

    const ecom_obj = {
      actionField: {
        list: list
      },
      products: [obj_elev_prod(prod, variant, 'cart_upd', prod_opts)]
    }

    const obj = {
      event: type,
      user_properties: elev_user(),
      ecommerce: {
        currencyCode: 'USD',
      }
    }

    if (type === 'dl_add_to_cart') {
      obj.ecommerce['add'] = ecom_obj
    } else {
      obj.ecommerce['remove'] = ecom_obj
    }   

    window.ElevarDataLayer.push(obj)
  }

  const li_cart_add = (product) => {
    // window.liQ.push({
    //   event: 'addToCart',
    //   email: customer ? customer.email : '',
    //   items: [
    //     {
    //       id: product.shop_id.toString(),
    //       price: parseFloat(product.first_product_price), 
    //       quantity: 1,
    //       currency: 'USD'
    //     }
    //    ]
    // })
  }




  // TILE EVENTS --------------------------------------------------------------
  // --------------------------------------------------------------------------


  const ga_tile_interact = ( product, action ) => {
    // Product Tile Interactions
    // Executed when a user interacts with a product tile on a category page. Interactions currently include:
    // - color selection
    // - image carousel
    // - quick add open
    

    const obj = {
      event: "dle_tileInteraction",
      tileInfo: {
        action: action,
        productId: deShopId(product.shop_id),
        productName: product.title
      }
    }

    window.dataLayer.push(obj)
  }

  const ga_tile_click = ( product, index, type=false ) => {
    // Ecommerce - Product List Click
    // Executed when a user clicks on a product found in a list, such as on a:
    // - product category page
    // - product upsell
    // - recommended products

    // - The data layer event should follow the same format currently used for product 
    // click events. The only required update should be to extend the usage of the event 
    // so it also covers product upsells and recommended products. The detection of these 
    // additions should be applied to the ecommerce.actionField.list value.


    let list_name = type ? type : get_list(product)

    const shop_id = deShopId(product.shop_id)
    const variant = (product.variants && product.variants[0]) ? product.variants[0].id.toString() : ''

    const obj = {
      event: "productClick",
      ecommerce: {
        currencyCode: 'USD',
        actionField: { 
          list: list_name
        },
        click: {
          products: [{
            name: product.title,
            id: shop_id,
            sku: shop_id,
            productId: shop_id,
            dimension2: product.gender === 'm' ? 'mens' : product.gender === 'w' ? 'womens' : 'unisex',
            variantId: variant,
            shopifyId: `shopify_US_${shop_id}_${variant}`,
            price: parseFloat(product.first_product_price),
            list: list_name,
            brand: 'buck mason',
            position: index,
            category: product.category ? product.category.toLowerCase() : '',
          }]
        }
      }
    }
    ga_push_event(obj, true)

    const ccc_evt = {
      event: 'content_card_click',
      eventCategory: list_name,
      eventAction: 'tile_click',
      eventLabel: product.title
    }

    ga_push_event(ccc_evt)
  }

  const elev_select = (prod, index, type=false) => {
    const list = type ? type : get_list(prod)

    const obj = {
      event: 'dl_select_item',
      user_properties: elev_user(),
      ecommerce: {
        currencyCode: 'USD',
        click: {
          actionField: {
            list: list,
            action: 'click'
          },
          products: [obj_elev_prod(prod, false, 'select', {list: list, index: index + 1})]
        }
      },
    }

    window.ElevarDataLayer.push(obj)
  }




  // SEARCH EVENTS ------------------------------------------------------------
  // --------------------------------------------------------------------------


  const ga_search_click = ( term, gender, is_popular ) => {
    // Site Search Clicks
    // Executed when a user clicks on a product search result or clicks through 
    // the curated Popular Searches suggestions. 

    // if ( is_popular ) term = el.text().toLowerCase()

    const obj = {
      event: "dle_siteSearchClick",
      searchInfo: {
        gender: gender,
        isPopular: is_popular,
        term: term
      }
    }

    window.dataLayer.push(obj)
    li_search(term)
  }

  const elev_search = (term, prods) => {
    // Site Search Clicks
    // Executed when a user searches

    const el_imprs = prods.slice(0,36).map( (x, index) => {
      let prod_obj = obj_elev_prod(x, false, 'list', {list: 'search results', index: index + 1})
      return prod_obj
    })

    const obj = {
      event: "dl_view_search_results",
      user_properties: elev_user(),
      ecommerce: {
        currencyCode: 'USD',
        actionField: { 
          list: 'search results',
          term: term
        },
        impressions: el_imprs, 
      }
    }

    window.ElevarDataLayer.push(obj)
  }

  const li_search = (term) => {
    // window.liQ.push({
    //   event: 'viewSearchResults',
    //   email: customer ? customer.email : '',
    //   searchTerm: term
    // })
  }




  // NEWSLETTER EVENTS ------------------------------------------------------------
  // --------------------------------------------------------------------------


  const ga_signup_news = () => {
    // Newsletter Signup
    // Executed when a user signs up to receive the newsletter. 

    const obj = {
      event: "dle_newsletterSuccess"
    }

    window.dataLayer.push(obj)
  }


  const elev_subscribe = (email) => {
    // Fired when user subscribes to email
    // newsletter

    const obj = {
      event: "dl_subscribe",
      lead_type: "email",
      user_properties: {
        customer_email: email
      }
    }
    window.ElevarDataLayer.push(obj)
  }


  const li_signup_news = (email) => {
    // window.liQ.push({
    //   event: 'conversion',
    //   name: 'email_signup',
    //   email: email
    // })
  }





  //  ------------------------------------------------------------



  const ga_push_event = (obj, ecom_null=false) => {
    if ( ecom_null ) window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(obj)
  }


  const ga_nav_click = ( data ) => {
    // Global Nav Link Clicks
    // Executed when a user clicks on a link within the global navigation and is 
    // taken to another page. Clicks to expand sections of the nav should not trigger this event.

    const obj = {
      event: "dle_globalNavClick",
      linkInfo: {
        hierarchy: data.hierarchy,
        text: data.text,
        url: data.url
      }
    }

    window.dataLayer.push(obj)
  }


  const ga_store_toggle = (action, store_name) => {
    // Shop Store Toggle
    // Executed when a user toggles on or off to shop a specific store. 

    const obj = {
      event: "dle_storeToggle",
      toggleInfo: {
        action: action,
        store: store_name
      }
    }

    window.dataLayer.push(obj)
  }


  const ana_account = () => {
    // Account Authentication
    // Executed when a user creates an account or logs into an existing account. 

    const el_obj = {
      event: '',
      user_properties: elev_user()
    }

    const action = localStorage.getItem('ga_login') ? 'log in' : localStorage.getItem('ga_register') ? 'create account' : ''
    el_obj.event = action === 'log in' ? 'dl_login' : action === 'create account' ? 'dl_sign_up' : ''
    let cust_id = customer ? customer.id.replace('gid://shopify/Customer/','') : ''

    const obj = {
      event: "dle_accountAuth",
      authInfo: {
        action: action,
        id: cust_id
      }
    }
    window.dataLayer.push(obj)
    window.ElevarDataLayer.push(el_obj)
    li_new_account()

    localStorage.removeItem('ga_login')
    localStorage.removeItem('ga_register')
  }

  const li_new_account = () => {
    // window.liQ.push({
    //   event: 'conversion',
    //   name: 'new_account',
    //   email: customer ? customer.email : ''
    // })
  }

  

  // cart_send, 
  
  return { 
    page_send, 
    ga_page,
    elev_customer,
    ana_home,
    ana_plp,
    ga_plp_filter,
    ga_plp_sort,
    ana_pdp, 
    ga_quick,
    elev_cart,
    ana_cart_add, 
    ana_cart_remove,
    ga_tile_interact,
    ga_tile_click,
    elev_select,
    ga_nav_click, 
    ga_search_click, 
    elev_search,
    ga_signup_news,
    elev_subscribe,
    li_signup_news,
    ga_store_toggle,
    ana_account
  }
};

export default useAnalytics;





