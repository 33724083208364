import React, { useState, useEffect } from "react"
import { useTheme } from '~/contexts/ThemeContext'
import { useProduct } from '~/contexts/ProductContext'
import { jnstyls } from '~/utils/helpers'

import { aria_btn } from "~/styles/app.module.scss"
import * as styles from "~/styles/pdp/pickup.module.scss"


const PickupStores = () => {
  const { pdpInv, pdpVariant, locate } = useTheme()

  const { 
    product, anyStores, nearInstock, currStore, setCurrStore, prodInv, setShowStoresPanel, 
    isPickup, onSetInstore, setAvailStoreName
  } = useProduct()
  
  const [storeAvail, setStoreAvail] = useState(false)
  const [regionOtherStore, setRegionOtherStore] = useState(false)
  const [regionAny, setRegionAny] = useState(false)


  const checkAvail = () => {
    if (!nearInstock?.length > 0) return

    if (!prodInv || !nearInstock?.length > 0) {
      setStoreAvail(false)
      setRegionOtherStore(false)
      setRegionAny(false)
      setAvailStoreName(null)
      return
    }

    let reg_shorts = nearInstock.map(x => x.short_name)
    const reg_inv = reg_shorts.filter(x => !!pdpVariant.inv[x])

    if (reg_inv.length < 1) {
      setStoreAvail(false)
      setRegionOtherStore(false)
      setRegionAny(false)
      setAvailStoreName(null)
      return
    }

    const store_enable = reg_inv.includes(currStore.short_name)
    const region_enable = nearInstock.find(x => x.short_name === reg_inv[0])

    setStoreAvail(store_enable)
    setRegionAny(true)
    setRegionOtherStore(region_enable)
    if (!store_enable && region_enable) setAvailStoreName(null)
    if (store_enable) setAvailStoreName(currStore.pima_title)
  }

  const handleInstore = () => {
    const obj = pdpVariant ? pdpVariant : {available: 'novar'}
    obj['prod_shop_id'] = product.shop_id
    obj['gender'] = product.gender
    // setStoresProduct(product)
    // setStoresOpen(obj)
    setShowStoresPanel(true)
    
    window._conv_q = window._conv_q || []
    // Goal: 100464617 = Pickup: Stores Panel
    window._conv_q.push(["triggerConversion", "100464617"])
  }

  useEffect(() => {
    if (!pdpInv) return

    checkAvail()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdpVariant, isPickup, nearInstock])

  useEffect(() => {
    onSetInstore(storeAvail ? currStore.pima_title : null)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeAvail, pdpVariant])

  useEffect(() => {
    if (!pdpInv || !currStore) return
    
    checkAvail()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currStore])

  return (
    <div 
      className={styles.pickup_stores}
      aria-hidden={pdpVariant ? 'false' : 'true'}
    >
      {(!locate) ? (
        <>
          <br/>
          <br/>
          <br/>
          <br/>
        </>
      ) : (locate && currStore && regionAny) ? (
        <>
          {storeAvail ? (
            `In stock at ${currStore.title} (${currStore.distance} mi)`
          ) : (
            <>
              Not in stock at {currStore.title}
              {regionOtherStore && (
                <>
                  <br/>
                  In stock at {regionOtherStore.title} ({regionOtherStore.distance} mi away)
                </>
              )}
            </>
          )}
          <br/>
          {(!storeAvail && regionOtherStore) && (
            <>
              <button 
                className={jnstyls([aria_btn, styles.link_btn])}
                onClick={(e)=> setCurrStore(regionOtherStore)}
                style={{marginTop: '0.25em'}}
                type={'button'}
              >
                Pickup at {regionOtherStore.title}
              </button>
              <span className={styles.divide}>|</span>
            </>
          )}
          <button 
            className={jnstyls([aria_btn, styles.link_btn])}
            onClick={(e)=> handleInstore()}
            style={{marginTop: '0.25em'}}
            type={'button'}
            cng-store=''
          >
            Change store
          </button>
        </>
      ) : (
        <>
          {(anyStores.length > 0) ? (
            <>
              Not available within 100 miles
              <br/>
              <button 
                className={jnstyls([aria_btn, styles.link_btn])}
                onClick={(e)=> handleInstore()}
                style={{marginTop: '0.25em'}}
                type={'button'}
                cng-store=''
              >
                Change location
              </button>
            </>
          ) : (
            <>
              Not in stock at any store locations.
              <br/>
              <br/>
              <br/>
              <br/>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default PickupStores


