// extracted by mini-css-extract-plugin
export var back = "store_panel-module--back--b3439";
export var box = "store_panel-module--box--b285a";
export var cart_btn = "store_panel-module--cart_btn--d261c";
export var clear = "store_panel-module--clear--b1e16";
export var close = "store_panel-module--close--2f77e";
export var collapse = "store_panel-module--collapse--c9ea6";
export var content = "store_panel-module--content--e5a0c";
export var detail = "store_panel-module--detail--d0bc9";
export var detail_btn = "store_panel-module--detail_btn--e691d";
export var details = "store_panel-module--details--f5674";
export var dist = "store_panel-module--dist--13c1d";
export var features = "store_panel-module--features--046e6";
export var gdirs = "store_panel-module--gdirs--e18f9";
export var head = "store_panel-module--head--d7f93";
export var hide_btn = "store_panel-module--hide_btn--1a0d2";
export var icon_close = "store_panel-module--icon_close--d57e2";
export var indicate = "store_panel-module--indicate--0971b";
export var info = "store_panel-module--info--c9f54";
export var infos = "store_panel-module--infos--11d95";
export var line_btn = "store_panel-module--line_btn--d351d";
export var loc_btn = "store_panel-module--loc_btn--40bb4";
export var notice = "store_panel-module--notice--4e7a7";
export var nsize_label = "store_panel-module--nsize_label--c512f";
export var nsizes = "store_panel-module--nsizes--58cb6";
export var pdp_content = "store_panel-module--pdp_content--8c347";
export var pdp_head = "store_panel-module--pdp_head--bdad7";
export var region = "store_panel-module--region--bed6b";
export var region_head = "store_panel-module--region_head--24a0c";
export var region_hide = "store_panel-module--region_hide--d40b6";
export var region_instock = "store_panel-module--region_instock--f95b8";
export var regions = "store_panel-module--regions--1b984";
export var search = "store_panel-module--search--5093f";
export var search_btn = "store_panel-module--search_btn--4878b";
export var select = "store_panel-module--select--64d09";
export var shop = "store_panel-module--shop--71d68";
export var sizes = "store_panel-module--sizes--0c646";
export var store = "store_panel-module--store--1b782";
export var store_panel = "store_panel-module--store_panel--b1ce5";
export var stores = "store_panel-module--stores--af04b";
export var stores_oos = "store_panel-module--stores_oos--55011";
export var title = "store_panel-module--title--fffc5";
export var title_btn = "store_panel-module--title_btn--68359";