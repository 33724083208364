// extracted by mini-css-extract-plugin
export var buyagain = "wardrobe-module--buyagain--16bbe";
export var cat_title = "wardrobe-module--cat_title--7c6a9";
export var colors = "wardrobe-module--colors--c0628";
export var empty = "wardrobe-module--empty--08714";
export var favbtn = "wardrobe-module--favbtn--ed9b7";
export var favprod = "wardrobe-module--favprod--690cb";
export var favtile = "wardrobe-module--favtile--1ae48";
export var images = "wardrobe-module--images--dd144";
export var img = "wardrobe-module--img--c85ab";
export var indicate = "wardrobe-module--indicate--8aa44";
export var owned = "wardrobe-module--owned--fa3b3";
export var owned_cat = "wardrobe-module--owned_cat--fd320";
export var owned_tile = "wardrobe-module--owned_tile--628fa";
export var owned_type = "wardrobe-module--owned_type--234c2";
export var owned_wr = "wardrobe-module--owned_wr--1f1c1";
export var share = "wardrobe-module--share--85404";
export var t_title = "wardrobe-module--t_title--a7338";
export var tiles = "wardrobe-module--tiles--77855";
export var title = "wardrobe-module--title--74317";
export var type_title = "wardrobe-module--type_title--63ca4";
export var wrdrb = "wardrobe-module--wrdrb--afb82";