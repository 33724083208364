import React, { useContext, createContext, useState, useEffect } from 'react'
import { useShopifyCookies } from '@shopify/hydrogen-react'
import { useStoresQuery } from "~/hooks/useStoresQuery"
import Geoloc from '~/utils/geoloc'
import UseWardrobe from '~/utils/use-wardrobe'

const ThemeContext = createContext()

const checkGender = () => {
  if (typeof window === "undefined") return 'men'
  const is_w = window.location.pathname.includes('/collections/women')
  let gend = localStorage.getItem('homeg') ? localStorage.getItem('homeg') : is_w ? 'women' : 'men'
  const params = new URLSearchParams(window.location.search)
  if (params.get('g')) gend =  params.get('g')
  document.querySelector('html').setAttribute('hg', gend)
  return gend
}

const ThemeProvider = ({ children }) => {
  const { getDistances } = Geoloc()
  const { checkUserFavs, getOrderHist } = UseWardrobe()
  const stores_json = useStoresQuery()
  useShopifyCookies({hasUserConsent: true})
  const shiplog = (typeof localStorage !== "undefined") && localStorage.getItem('shiplog')

  const [isSHM, setIsSHM] = useState(null)
  const [isMobile, setIsMobile] = useState(null)
  const [isTablet, setIsTablet] = useState(null)
  const [isTouches, setIsTouches] = useState(null)
  const [locate, setLocate] = useState(null)
  const [freeze, setFreeze] = useState(false)
  const [gender, setGender] = useState(checkGender())
  const [bread, setBread] = useState(false)
  const [pdpVariant, setPdpVariant] = useState(false)
  const [cart, setCart] = useState(null)
  const [cartClose, setCartClose] = useState(false)
  const [cartOpen, setCartOpen] = useState(false)
  const [cartChanging, setCartChanging] = useState(false)
  const [cartError, setCartError] = useState(false)
  const [cartRecs, setCartRecs] = useState([])
  const [mobileMenu, setMobileMenu] = useState(false)
  const [collPanel, setCollPanel] = useState(false)
  const [collGender, setCollGender] = useState(null)
  const [storesOpen, setStoresOpen] = useState(false)
  const [blockHeader, setBlockHeader] = useState(false)
  const [pdpInv, setPdpInv] = useState(false)
  const [search, setSearch] = useState(false)
  const [showSizing, setShowSizing] = useState(false)
  const [showGiftCard, setShowGiftCard] = useState(false)
  const [giftInfo, setGiftInfo] = useState(false)
  const [quick, setQuick] = useState(false)
  const [analytics, setAnalytics] = useState({})
  const [customer, setCustomer] = useState(false)
  const [custfavs, setCustfavs] = useState(false)
  const [orders, setOrders] = useState([])
  const [order, setOrder] = useState(null)
  const [pageProps, setPageProps] = useState({pageType: null})
  const [transBg, setTransBg] = useState('#fff')
  const [shipPref, setShipPref] = useState('ship')
  const [storesData, setStoresData] = useState(stores_json)
  const [showLoginWarn, setShowLoginWarn] = useState(false)
  const [headTest, setHeadTest] = useState(false)

  const storesSort = () => {
    let coords = false
    if (!locate.usrCoords && !!locate.pdpStore) {
      const pdpstore = storesData.flatMap(x => x.stores).find(x => x.short_name === locate.pdpStore)
      if (pdpstore) coords = pdpstore.coordinates
    }
    let sorted = getDistances(storesData, locate, coords)
    sorted = sorted.filter(x => x.stores.length > 0)
    setStoresData(sorted)

    if (locate.currStores.length > 0) {
      const currStore = storesData.flatMap(x => x.stores)
                    .filter(x => !!x.pickup_enabled)
                    .find(x => x.short_name === locate.currStores[0])
      if (currStore) setShipPref('pickup')
    }
  }

  const checkFavs = async (fav_id=false) => {
    if (!localStorage.getItem('ward')) return
    if (!customer) return
    if (!!custfavs) return

    let favs = false
    if (fav_id) {
      favs = await checkUserFavs({id: fav_id})
      
    } else if (customer) {
      favs = await checkUserFavs({sid: customer.id})
    }

    if (!favs) {
      setCustfavs(null)
      return
    }

    setCustfavs(favs)
    if (!localStorage.getItem('wrdrb')) localStorage.setItem('wrdrb', favs.id)
  }

  useEffect(() => {
    const ward_id = localStorage.getItem('wrdrb')
    if (ward_id) checkFavs(ward_id)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!locate) return
    localStorage.setItem('bmloc', JSON.stringify(locate))
    storesSort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locate])

  useEffect(() => {
    document.querySelector('html').style.overflow = freeze ? 'hidden' : ''
  }, [freeze])

  useEffect(() => {
    let loggedout = !customer

    if (loggedout) {
      setCustfavs(null)
      return
    }

    const skip = localStorage.getItem('wrdrb') && !loggedout && !custfavs
    if (!loggedout && !skip) checkFavs()
    loggedout = !customer || !customer?.full
      
    if (!loggedout && !localStorage.getItem('ords') && localStorage.getItem('ward')) getOrderHist(customer)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer])

  useEffect(() => {
    document.querySelector('html').setAttribute('hg', gender)
    localStorage.setItem('homeg', gender)
  }, [gender])

  useEffect(() => {
    if (!cartOpen) setCartClose(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartOpen]);



  return (
    <ThemeContext.Provider
      value={{
        isSHM, setIsSHM, shiplog, 
        analytics, setAnalytics,
        isMobile, isTablet, isTouches,
        setIsMobile, setIsTablet, setIsTouches,
        locate, setLocate, 
        gender, setGender,
        bread, setBread,
        freeze, setFreeze,
        pdpVariant, setPdpVariant,
        cart, setCart,
        cartClose, setCartClose,
        cartOpen, setCartOpen,
        cartChanging, setCartChanging,
        cartError, setCartError,
        cartRecs, setCartRecs,
        mobileMenu, setMobileMenu,
        collPanel, setCollPanel,
        collGender, setCollGender,
        storesOpen, setStoresOpen,
        blockHeader, setBlockHeader,
        pdpInv, setPdpInv,
        quick, setQuick,
        search, setSearch,
        showSizing, setShowSizing,
        showGiftCard, setShowGiftCard,
        giftInfo, setGiftInfo,
        customer, setCustomer,
        custfavs, setCustfavs,
        orders, setOrders,
        order, setOrder,
        pageProps, setPageProps,
        transBg, setTransBg,
        shipPref, setShipPref,
        storesData, setStoresData,
        showLoginWarn, setShowLoginWarn,
        headTest, setHeadTest
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export function useTheme() {
  return useContext(ThemeContext)
}

export { ThemeContext, ThemeProvider }


// const defaultState = {
//   dark: false,
//   toggleDark: () => {},
// }

// const supportsDarkMode = () =>
//   window.matchMedia("(prefers-color-scheme: dark)").matches === true

// const state = {
//   dark: false,
//   panel: 'panel-men',
//   sub: false
// }

// const toggleDark = () => {
//   let dark = !this.state.dark
//   localStorage.setItem("dark", JSON.stringify(dark))
//   this.setState({ dark })
// }
// state,
// toggleDark: toggleDark,
// togglePanel: togglePanel,
// toggleSub: toggleSub,

// useEffect(() => {
//   const lsDark = JSON.parse(localStorage.getItem("dark"))
//   if (lsDark) {
//     this.setState({ dark: lsDark })
//   } else if (supportsDarkMode()) {
//     this.setState({ dark: true })
//   }
// }, []);
