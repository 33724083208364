import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from '@reach/router'
import { useTheme } from '~/contexts/ThemeContext'
import { AccountProvider } from '~/contexts/AccountContext'
import QuickContext from '~/contexts/QuickContext'
import useAnalytics from '~/utils/analytics'
import useShopCustomerHook from '~/utils/use-customer'
import useTestFunnelHook from '~/utils/test-funnel'
import FirstLoad from '~/utils/first-load'
import Kora from '~/utils/kora'

import Header from '~/components/header'
import Footer from '~/components/footer'
import Cart from '~/components/cart'
import MobileMenu from '~/components/header/mobile-menu'
import CartNotif from '~/components/cart/notif'
import Search from '~/components/panels/search'
import StoresPanel from '~/components/panels/stores-panel'
import Sizing from '~/components/pdp/sizing'
import GiftCard from '~/components/pdp/gift-card'
import LoginWarn from '~/components/shared/login-warn'

import '~/styles/app.scss';

export const Layout = ({ children, pageContext }) => {
  const { fInit } = FirstLoad()
  const { doKora } = Kora()
  const location = useLocation()

  const { 
    cartOpen, mobileMenu, setMobileMenu, customer, storesOpen, quick, search, showSizing, 
    setShowSizing, showGiftCard, setPdpVariant, setFreeze, showLoginWarn 
  } = useTheme()

  const { ga_page, page_send, elev_customer } = useAnalytics()
  const { check_refresh } = useShopCustomerHook()
  const { fnnl_page } = useTestFunnelHook()

  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const mainRef = useRef(null)

  const pathname = location.pathname
  let pageType = 'page' // default

  const policies = ['/pages/terms','/pages/privacy','/pages/customer-data','/pages/accessibility']

  if (pathname === '/') {
    pageType = 'home'
  } else if (pathname.includes('/preview/')) {
    pageType = 'preview'
  } else if (pathname.includes('/collections/')) {
    pageType = 'collection'
  } else if (pathname.includes('/account')) {
    pageType = 'account'
  } else if (pathname === '/blogs/journal') {
    pageType = 'blog'
  } else if (pathname.includes('/feature/') || pathname.includes('/blogs/journal/')) {
    pageType = 'article'
  } else if (pathname.includes('/products/') && pathname.includes('gift')) {
    pageType = 'giftCard'
  } else if (policies.includes(pathname)) {
    pageType = 'policy'
  } else if (pathname.includes('/products/')) {
    pageType = 'product'
  }


  useEffect(() => {
    fInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fnnl_page()
    let other_page = !location.pathname.includes('/products') && !location.pathname.includes('/collections') && location.pathname !== '/'
    if (pageType === 'preview') other_page = false

    if (!location.pathname.includes('/products')) setPdpVariant(false)

    if (process.env.NODE_ENV !== "production") return
      
    page_send(pageType)
    doKora()
    if (other_page && typeof document !== 'undefined') {
      ga_page()
      elev_customer()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  useEffect(() => {
    const loggedout = !customer || !customer?.full || !customer.check_expire
    if (loggedout) return
    check_refresh()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer])

  useEffect(() => {
    if (showMobileMenu) {
      setMobileMenu(true)
      return
    }
    setMobileMenu(false)
    setFreeze(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMobileMenu])


  if (pathname.includes('api-rms')) return (<main>{children}</main>)


  return (
    <AccountProvider>
      <Header setShowMobileMenu={setShowMobileMenu} />

      {(mobileMenu) && (
        <MobileMenu setShowMobileMenu={setShowMobileMenu} />
      )}

      <main ref={mainRef}>
        {children}
        {/* <PageTrans>
        </PageTrans> */}
      </main>

      {(cartOpen && cartOpen !== 'notif') && (
        <Cart />
      )}

      {(cartOpen && cartOpen === 'notif') && (
        <CartNotif />
      )}

      {quick && (
        <QuickContext quick_data={quick} />
      )}

      {search && (
        <Search />
      )}

      {storesOpen && (
        <StoresPanel variant={storesOpen} />
      )}

      {showSizing && (
        <Sizing guide={showSizing} onClose={setShowSizing} />
      )}

      {showGiftCard && (
        <GiftCard />
      )}

      {showLoginWarn && (
        <LoginWarn />
      )}

      <Footer />
    </AccountProvider>
  )
}

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// }

export default Layout








