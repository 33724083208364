import { useTheme } from '~/contexts/ThemeContext'
import Geoloc from '~/utils/geoloc'
import QlReq from '~/utils/ql-req'
import * as Queries from '~/helpers/ql-queries'
import UseWardrobe from '~/utils/use-wardrobe'
import useCartApi from '~/utils/use-cart'
import { setCookie, checkMobile } from '~/utils/helpers'

const FirstLoad = () => {
  const { 
    shiplog, cart, setCart, setIsMobile, setIsTablet, setIsTouches,
    setLocate, setCartRecs, setCustomer, setIsSHM, setHeadTest
  } = useTheme()
  const { getGeo } = Geoloc()
  const { checkShopUser } = UseWardrobe()
  const { addDiscount } = useCartApi()

  const keyListen = (e) => {
    if (e.key === 'Tab') {
      document.querySelector('html').classList.add('using-keyboard')
      window.addEventListener('click', keyClear)
    }
  }

  const keyClear = (e) => {
    document.querySelector('html').classList.remove('using-keyboard')
    window.removeEventListener('keydown', keyClear)
    window.removeEventListener('click', keyClear)
  }

  const addListeners = () => {
    window.addEventListener('resize', checkBrowser, { passive: true })
    window.addEventListener('keydown', keyListen)
  }

  const checkTestParams = () => {
    const params = new URLSearchParams(window.location.search)

    if (window.location.search.includes('utest=true')) {
      localStorage.setItem('unitary','true')
    }
    if (window.location.search.includes('utest=false')) {
      localStorage.removeItem('unitary')
    }

    if (params.get('outf')) {
      const ttype = params.get('outf')
      if (ttype === 'false') {
        sessionStorage.removeItem('outftest')
      } else {
        sessionStorage.setItem('outftest','true')
      }
    }
    if (params.get('clear')) {
      const ttype = params.get('clear')
      if (ttype === 'cart') {
        localStorage.removeItem('cart')
        setCart(null)
      }
    }
    if (params.get('ward')) {
      const ttype = params.get('ward')
      if (ttype === 'false') {
        localStorage.removeItem('ward')
      } else {
        localStorage.setItem('ward','true')
      }
    }
    if (params.get('revtest')) {
      const ttype = params.get('revtest')
      if (ttype === 'false') {
        sessionStorage.removeItem('revtest')
      } else {
        sessionStorage.setItem('revtest','true')
      }
    }
    if (params.get('nmobi')) {
      const ttype = params.get('nmobi')
      if (ttype === 'false') {
        sessionStorage.removeItem('nmobi')
        setHeadTest(false)
      } else {
        sessionStorage.setItem('nmobi','true')
        setHeadTest(true)
      }
    }
  }

  const checkBrowser = () => {
    const is_stag = process.env.GATSBY_CURR_ENV === 'development'
    const mobile_info = checkMobile()
    document.querySelector('html').setAttribute('e', is_stag ? 'd' : 'p')

    const el = document.querySelector("html")
    el.setAttribute('is-m', mobile_info.mobile ? 'true' : 'false') 
    mobile_info.touches ? el.setAttribute('is-t','true') : el.removeAttribute('is-t')
    setIsMobile(mobile_info.mobile)
    setIsTablet(mobile_info.tablet)
    setIsTouches(mobile_info.touches)
  }

  const checkSHM = async () => {
    const query = Queries.q_metafield()
    const vars = {
      namespace: 'pima',
      key: 'shopify_holiday_mode'
    }
    const req = await QlReq.post(query, vars)
    let metacheck = req.shop.metafield.value === 'true'
    if (shiplog && metacheck) console.log('SHM on 🎄')
    setIsSHM(metacheck)
  }

  const checkLocate = async () => {
    if (typeof localStorage === "undefined") return
      
    const geo = await getGeo()
    setLocate(geo)
  }

  const checkReferrer = () => {
    if (typeof document === 'undefined') return
    const params = new URLSearchParams(window.location.search)
    const obj = {
      p: window.location.pathname,
      r: document.referrer.includes('buckmason.com') ? '' : document.referrer,
      a: params.get('utm_campaign') || false,
      o: params.get('utm_content') || false,
      m: params.get('utm_medium') || false,
      s: params.get('utm_source') || false,
      t: params.get('utm_term') || false,
      d: Date.now
    }
    setCookie('re', JSON.stringify(obj), null, null, 1)
  }

  const checkCustomer = () => {
    let cust = false
    
    if ((typeof localStorage !== "undefined") && localStorage.getItem('cu')) {
      const cust_obj = JSON.parse(localStorage.getItem('cu'))
      const expires = Date.parse(cust_obj.expires)
      cust_obj['check_expire'] = true
      if ( Date.now() < expires ) cust = cust_obj
    }
    
    setCustomer(cust)
    if (!cust) checkKnownParam()
  }

  const checkKnownParam = async () => {
    const params = new URLSearchParams(window.location.search)

    let obj = false
    if (params.get('shid')) {
      obj = {shid: params.get('shid')}
      params.delete('shid')
      const new_url = params.size ? `?${params.toString()}` : window.location.pathname
      window.history.replaceState(null, null, new_url)
    }
    if (params.get('kv_id')) {
      obj = {kv_id: params.get('kv_id')}
      params.delete('kv_id')
      const new_url = params.size ? `?${params.toString()}` : window.location.pathname
      window.history.replaceState(null, null, new_url)
    }
    if (!obj) return
    
    const known = await checkShopUser(obj)
    if (!known) return
      
    known.full = false
    setCustomer(known)
    localStorage.setItem('cu',JSON.stringify(known))
  }

  const checkCart = () => {
    if ( (typeof localStorage === "undefined") || !localStorage.getItem('cart')) {
      setCart(null)
      return
    }
    const loc_cart = JSON.parse(localStorage.getItem('cart'))
    loc_cart['refresh'] = 'loc'
    setCart(loc_cart)
    checkCartValid()
  }

  const checkCartValid = async () => {
    const loc_cart = JSON.parse(localStorage.getItem('cart'))

    const vars = {
      id: loc_cart.id
    }
    const query = Queries.q_cart_valid()
    const req = await QlReq.post(query, vars)

    const is_valid = req.cart !== null

    if (!is_valid) {
      localStorage.removeItem('cart')
      setCart(false)
      return
    }

    loc_cart['refresh'] = true
    localStorage.setItem('cart', JSON.stringify(loc_cart))
    setCart(loc_cart)
  }

  const checkDiscount = () => {
    const params = new URLSearchParams(window.location.search)
    if (!cart && params.get('discount')) sessionStorage.setItem('autodis', params.get('discount'))
    if (cart && params.get('discount')) addDiscount(cart.id, params.get('discount'))
  }

  const checkRecs = () => {
    if ( (typeof localStorage === "undefined") || !localStorage.getItem('cartRecs')) {
      setCartRecs([])
      return
    }
    const loc_recs = JSON.parse(localStorage.getItem('cartRecs'))
    const time_diff = Date.now() - loc_recs.time
    if (time_diff > 10800000) {
      localStorage.removeItem('cartRecs')
      setCartRecs([])
      return
    }
    setCartRecs(loc_recs.recs)
  }

  // const checkGender = () => {
  //   const is_w = window.location.pathname.includes('/collections/women')
  //   let gend = localStorage.getItem('homeg') ? localStorage.getItem('homeg') : is_w ? 'women' : 'men'
  //   const params = new URLSearchParams(window.location.search)
  //   if (params.get('g')) {
  //     gend =  params.get('g')
  //     console.log('params',gend)
  //   }
  //   console.log(gend)
  //   document.querySelector('html').setAttribute('hg', gend)
  //   setGender(gend)
  // }

  const fInit = () => {
    checkLocate()
    checkTestParams()
    checkReferrer()
    checkBrowser()
    checkSHM()
    // checkGender()
    checkCustomer()
    checkKnownParam()
    checkCart()
    checkDiscount()
    addListeners()
    checkRecs()
  }


  return { fInit }
};

export default FirstLoad






