import * as React from "react"
import { ProductProvider } from '~/contexts/ProductContext'
import QuickWrap from '~/components/panels/quick-wrap'



const QuickContext = ({ quick_data }) => {
  const product = quick_data.products.find(x => x.shop_id === quick_data.selected)

  return (
    <ProductProvider key={product.id} productData={product} isQuick={true}>
      <QuickWrap key={product.id} quick_data={quick_data} />
    </ProductProvider>
  );
}

export default QuickContext
