import * as React from "react"
import { useTheme } from '~/contexts/ThemeContext'
import Flink from '~/components/shared/flink'
import Modal from '~/components/shared/modal'

import { solid_btn } from "~/styles/app.module.scss"
import * as styles from "~/styles/pdp/giftCard.module.scss"


const LoginWarn = () => {
  const { setShowLoginWarn } = useTheme()

  const is_stag = process.env.GATSBY_CURR_ENV === 'development'
  const auth_url = is_stag ? 'https://buckmason-rms.pima.io/auth' : 'https://orders.buckmason.com/auth'
  
  const handleClose = () => {
    setTimeout(()=>{setShowLoginWarn(false)}, 200)
  }

  return (
    <div className={styles.gcwrap}>
      <Modal name={'Login Warn'} childStyles={styles.logwarn} onModalClose={handleClose}>
        <div className={styles.title} styles={{fontSize: '1.15em'}}>Would you like to log in?</div>
        <p>We found your wishlist items but we<br/>don't recognize this browser</p>
        <div className={styles.loglink}>
          <Flink
            className={solid_btn}
            href={auth_url}
          >
            Sign in to your account
          </Flink>
        </div>
      </Modal>
    </div>
  )
};

export default LoginWarn




